import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import "../Container.css";
import { useAuth } from "../../../Utils/AuthContext";
import { useSelector, useDispatch } from "react-redux";
import Card from "react-bootstrap/Card";
import API from "../../../Utils/API";
import { useHistory, useRouteMatch } from "react-router-dom";
import RatingForm from "../../DialogBox/RatingForm";
import LoginForm from "../../DialogBox/LoginForm";
import { getUserSnips } from "../../../Redux/Snips/SnipsAction";
import ReactStars from "react-rating-stars-component";

const ProductCard = ({
  branchName,
  storeId,
  productId,
  img,
  branchLogo,
  heading,
  avgRating,
  discount,
  actualPrice,
  offerPrice,
  totalLikes,
}) => {
  const [ratingForm, setRatingForm] = useState(false);
  const [loginForm, setLoginForm] = useState(false);
  const { allStoreList } = useSelector((state) => state.stores);
  const { loading, allLikes } = useSelector((state) => state.snips);
  const [likes, setLikes] = useState(totalLikes);
  const [likesLoading, setLikesLoading] = useState(false);
  const [liked, setLiked] = useState(false);
  const [reload, setReload] = useState(false);
  const { userInfo } = useAuth();
  const history = useHistory();
  const dispatch = useDispatch();
  const cardClick = (newValue, productId) => {
    if(window.location.href.includes("productDetail")) {
      history.replace(`/productDetail/` + newValue + "/" + productId);
      window.location.reload();
    } else {
      history.push(`/productDetail/` + newValue + "/" + productId);
    }
  };
  useEffect(() => {
    allLikes?.length > 0 && allLikes.filter((e) => e.productId === productId).length > 0
      ? setLiked(true)
      : setLiked(false);
  }, [allLikes]);
  const ratingClick = (newValue) => {
    // history.push(`/storeDetails/` + newValue);
    if (!userInfo?.email) setLoginForm(true);
    else setRatingForm(true);
    // console.log("Under Development..............")
  };

  const makeLikes = (newValue) => {
    // history.push(`/storeDetails/` + newValue);
    if (!userInfo?.email) setLoginForm(true);
    else {
     
      if (
        allLikes != undefined &&
       // allLikes.filter((e) => e.type === "Product") &&
        allLikes.filter((e) => e.productId == newValue).length == 0 &&
        !loading &&
        !likesLoading
      ) {
        /* vendors contains the element we're looking for */
        setLikesLoading(true);
        API.makeLikes(userInfo?.email, newValue, "product")
          .then((result1) => {
            if (result1 != undefined) {
              dispatch(getUserSnips(userInfo?.email, "like"));
              setLikesLoading(false);
              API.productById("gust", newValue)
                .then((result) => {
                  if (result !== undefined) {
                    setLikes(result.totalLikes);
                  }
                })
                .catch((error) => {
                  this.setState({ loading: false });
                  console.log(error);
                  // console.log(error);
                });
            } else {
              // setOffer("")
              setLikesLoading(false);
              // setLoading(false);
            }
          })
          .catch((error) => {
            // setLoading(false);
            setLikesLoading(false);
            console.log(error);
            // console.log(error);
          });
      } else if(!loading && !likesLoading && allLikes != undefined && allLikes.filter((e) => e.productId == newValue && e.activityType == "like").length>0) {
        setLikesLoading(true);
        const productId =
          allLikes !== undefined &&
          allLikes.filter((e) => e.productId === newValue)[0].id;
        API.makeUnLikes(userInfo?.email, newValue, "product", productId)
          .then((result) => {
            if (result !== undefined) {
              dispatch(getUserSnips(userInfo?.email, "like"));
              setLikesLoading(false);
              // setLoading(false);
              API.productById("gust", newValue)
                .then((result) => {
                  if (result !== undefined) {
                    setLikes(result.totalLikes);
                  }
                })
                .catch((error) => {
                  this.setState({ loading: false });
                  console.log(error);
                  // console.log(error);
                });
            } else {
              // setOffer("")
              setLikesLoading(false);
              // setLoading(false);
            }
          })
          .catch((error) => {
            // setLoading(false);
            console.log(error);
            setLikesLoading(false);
          });
        console.log("already liked this product thanks.");
       
      }
    }

    // console.log("Under Development..............")
  };

  const handleOpenForm = () => setRatingForm(true);

  const handleCloseForm = () => setRatingForm(false);

  const handleLoginOpenForm = () => setLoginForm(true);

  const handleLoginCloseForm = () => setLoginForm(false);

  const renderStoreName = (newValue) => {
    let storeName = "";
    if (allStoreList != undefined && allStoreList.length > 0) {
      const obj = allStoreList.filter((item) => item.id == newValue);
      if (obj != undefined && obj.length > 0) storeName = obj[0].storeName;
    }
    return <span>{storeName}</span>;
  };

  return (
    <React.Fragment>
      <Card className="mb-3 mr-3 related-products offer-page">
        <Card.Img
          onClick={() => {
            cardClick(storeId, productId);
          }}
          style={{ cursor: "pointer" }}
          variant="top"
          className="px-3 pt-3"
          src={
            img != undefined &&
            img != "" &&
            img != null &&
            img.length > 0 != null
              ? img[0]
              : "https://storage.googleapis.com/assets.shopiads.ca/imagesProd/No-image-found.jpg"
          }
        />
        <Card.Body className="p-3">
          <div
            onClick={() => {
              cardClick(storeId, productId);
            }}
            style={{ cursor: "pointer" }}
          >
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                {/* <img src={branchLogo != undefined && branchLogo != "" && branchLogo != null ? branchLogo : "https://storage.googleapis.com/assets.shopiads.ca/imagesProd/No-image-found.jpg"} alt={heading} height="40" width="40" /> */}

                <p className="brand-name">{renderStoreName(storeId)}</p>
              </div>
            </div>
            <p className="p-name mb-0">{heading}</p>
            <div class="price">
              <span style={{ marginTop: "2px" }}>
                {localStorage.getItem("connectSite") == "CA" ? "CAD" : "US $"}
              </span>
              <div>{offerPrice}</div>
              {actualPrice > offerPrice ? (
                <div className="org-price">
                  <div>{actualPrice}</div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="discount">
            <div>
              <div
                style={{ cursor: "pointer" }}
                className="ratings d-flex align-items-center"
              >
                <ReactStars
                  key={`stars_${Number(avgRating)}`}
                  count={5}
                  size={24}
                  isHalf={true}
                  edit={false}
                  value={Number(avgRating)}
                  activeColor="#ffd700"
                />
                <div className="rating">
                  {avgRating != undefined && Number(avgRating) > 0
                    ? avgRating
                    : 0}
                </div>
              </div>
            </div>
            <div>
              {liked ? (
                <div
                  onClick={() => {
                    makeLikes(productId);
                  }}
                  style={{ cursor: "pointer" }}
                  className="d-flex align-items-center fav ml-auto"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16.69"
                    viewBox="0 0 28 25.69"
                  >
                    <path
                      id="ic_favorite_border_24px"
                      d="M22.3,3A8.384,8.384,0,0,0,16,5.926,8.384,8.384,0,0,0,9.7,3,7.625,7.625,0,0,0,2,10.7c0,5.292,4.76,9.6,11.97,16.156L16,28.69l2.03-1.848C25.24,20.3,30,15.992,30,10.7A7.625,7.625,0,0,0,22.3,3ZM16.14,24.77l-.14.14-.14-.14c-6.664-6.034.14,4.046.14,0,0-2.8-2.94,0-.14,0,2.156,0-1.9-17.584-1.162-15.666L16.14,25.577c.728-1.918,5.72-6.446,7.876-6.446,2.8,0,2.743-10.627,2.743-7.827,0,3.395-3.578,6.217-8.729,10.927C17.422,22.788,17.213,23.8,16.14,24.77Z"
                      transform="translate(-2 -3)"
                      fill="#1d6042"
                    />
                  </svg>
                  <div className="rating">
                    {Intl.NumberFormat("en-US", {
                      notation: "compact",
                      maximumFractionDigits: 1,
                    }).format(likes)}
                  </div>
                </div>
              ) : (
                <div
                  onClick={() => {
                    makeLikes(productId);
                  }}
                  style={{ cursor: "pointer" }}
                  className="fav ml-auto d-flex align-items-center"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="#1d6042"
                    className="bi bi-heart"
                    viewBox="0 0 16 16"
                  >
                    <path d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01L8 2.748zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143c.06.055.119.112.176.171a3.12 3.12 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15z"></path>
                  </svg>
                  <div className="rating">
                    {Intl.NumberFormat("en-US", {
                      notation: "compact",
                      maximumFractionDigits: 1,
                    }).format(likes)}
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="align-items-center justify-content-between d-none">
            <div
              onClick={() => {
                ratingClick(storeId);
              }}
              style={{ cursor: "pointer" }}
              className="ratings"
            >
              <ReactStars
                key={`stars_${Number(avgRating)}`}
                count={5}
                size={24}
                isHalf={true}
                edit={false}
                value={avgRating}
                activeColor="#ffd700"
              />
            </div>
            <p className="mb-0 h6">
              {avgRating != undefined && Number(avgRating) > 0 ? avgRating : 0}{" "}
              Rating
            </p>
          </div>
        </Card.Body>
      </Card>
      {ratingForm && (
        <RatingForm
          handleOpen={handleOpenForm}
          handleClose={handleCloseForm}
          open={ratingForm}
          storeId={storeId}
          productId={productId}
          offerId={""}
          type={"Product"}
        />
      )}
      {loginForm && (
        <LoginForm
          handleOpen={handleLoginOpenForm}
          handleClose={handleLoginCloseForm}
          open={loginForm}
        />
      )}
    </React.Fragment>
  );
};
export default ProductCard;
