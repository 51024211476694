import React,{useState} from "react";
import CallRoundedIcon from "@material-ui/icons/CallRounded";
import MailRoundedIcon from "@material-ui/icons/MailRounded";
import AppBar from "@material-ui/core/AppBar";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import InstagramIcon from "../../Assets/instagram.svg";
import FacebookIcon from "../../Assets/facebook.svg";
import TwitterIcon from "../../Assets/twitterx.png";
import downloadQr from "../../Assets/downloadAppQr.png";
import downloadQrUS from "../../Assets/downloadAppQrUS.png";
import shopifyBadge from "../../Assets/Shopify-App-Store-Badge.png";
import LoginForm from "../DialogBox/LoginForm";
import { useAuth } from "../../Utils/AuthContext";
import { useHistory } from "react-router-dom";
import environment from "../../Utils/env";


const useStyles = makeStyles((theme) => ({
  appBar: {
    padding: "0 4%",
    backgroundColor: "#1d6042",
    bottom: 0,
    top: "auto",
  },
}));



function Footer() {
  const classes = useStyles();
  const { userInfo } = useAuth();
  const history = useHistory();
  const [loginForm, setLoginForm] = useState(false);
  const handleLoginOpenForm = () => setLoginForm(true);

const handleLoginCloseForm = () => setLoginForm(false);

const contactUs = (name, url) => {
  // history.push(`/storeDetails/` + newValue);
  if (!userInfo?.email) setLoginForm(true);
  else history.replace(url);
  // console.log("Under Development..............")
};

const aboutUs = (name, url) => {
  history.replace(url);
  window.scrollTo(0, 0);
}

  return (
    <Grid container>
      <Grid item xs={12}>
        <AppBar position="unset" className={classes.appBar}>
          <div className="footer">
            <div className="sections row container-fluid">
              <section className="section1 col-md-2">
              <a onClick={() => contactUs("contact us", "/contact")} href="javascript:void(0)"><p>Contact Us</p></a>
                <a href="/privacyPolicy" onClick={() => window.scrollTo(0,0)}><p>Privacy Policy</p></a>
                <a href="/termsOfuse" onClick={() => window.scrollTo(0,0)}><p>Terms Of USe</p></a>
              </section>
              <section className="section1 col-md-2">
              <a onClick={() => aboutUs("about us", "/about")} href="javascript:void(0)"><p>About Us</p></a>
              <a onClick={() => aboutUs("FAQ", "/faq")} href="javascript:void(0)"><p>FAQ</p></a>
              <a href={localStorage.getItem("connectSite")==="CA"?`${environment.CANADA_BASE_URL}/ADMIN-UI`:`${environment.USA_BASE_URL}/ADMIN-UI`} target="_new"><p>Vendor Login</p></a>
              
                {/* <p>
                  <CallRoundedIcon /> +1-647-995-9501
                </p> */}
                {/* <p>
                  <CallRoundedIcon /> 9282 3323 92
                </p> */}
                {/* <p>
                  <MailRoundedIcon /> info@shopiads.com
                </p> */}
              </section>
              <section className="section1 col-md-2">
                <p>Follow us on</p>
                <div className="socialIcons">
                  <div>
                  <a href="https://www.facebook.com/profile.php?id=100084814547567" target="_new" className="mb-2 text-white d-flex align-items-center"><img src={FacebookIcon} className="mr-3"></img> Facebook</a>
                  <a href="https://www.instagram.com/shopiads/" target="_new" className="mb-2 text-white d-flex align-items-center"><img src={InstagramIcon} className="mr-3"></img> Instagram</a>
                  <a href="https://twitter.com/ShopiAds" target="_new" className="mb-2 text-white d-flex align-items-center"><img src={TwitterIcon} className="mr-3" style={{backgroundColor:'white',width:30,height:30,borderRadius:'50%',padding:'3px'}}/> Twitter</a>
                  </div>
                  {/* <i className="fa fa-facebook-official" aria-hidden="true"></i>
                  <i className="fa fa-instagram" aria-hidden="true"></i>
                  <i className="fa fa-twitter" aria-hidden="true"></i>
                  <i className="fa fa-linkedin" aria-hidden="true"></i> */}
                  {/* <i
              className="fa fa-facebook"
              aria-hidden="true"
              style={{ color: "#0C86EF" }}
            ></i>
            <i
              className="fa fa-instagram"
              aria-hidden="true"
              style={{ color: "#E2558C" }}
            ></i>
            <i
              className="fa fa-twitter"
              aria-hidden="true"
              style={{ color: "#1DA1F2" }}
            ></i> */}
                </div>
              </section>
              <section className="section1 col-md-2">
                <p>Mobile APP DOWNLOAD</p>
                <div className="socialIcons qrcode">
                  <div className="d-flex">
                  <img src={localStorage.getItem("connectSite")=="CA"?downloadQr:downloadQrUS}></img>
                  <div className="pl-2">
                    <a href={localStorage.getItem("connectSite")=="CA"?"https://apps.apple.com/ca/app/shopiads/id1601835714":"https://apps.apple.com/us/app/shopiads/id1601835714"} target="_new" className="d-block"><p>App Store</p></a>
                    <a href="https://play.google.com/store/apps/details?id=com.apcod.shopiads" target="_new" className="d-block"><p>Google Play</p></a>
                  </div>
                  </div>
                </div>
              </section>

              <section className="section1 col-md-2">
                
                <div className="shopifyBadge qrcode">
                  <div className="d-flex">
                  <a href="https://apps.shopify.com/shopiads?utm_source=shopiads_web&utm_medium=web&utm_campaign=sellers&utm_id=5" target="_new" className="text-white pr-0"> <img  width={"200px"} src={shopifyBadge}></img></a>
                 
                  </div>
                </div>
              </section>

              <div className="copyRight col-md-12 text-center">
              <p className="mb-0 mt-3">Copyright &copy; 2024 ShopiAds Inc.Trademarks are the property of respective store owners. All right reserved</p>
            </div>
            </div>
            
          </div>
        </AppBar>
        {/* <AppBar position="fixed" className= "layout-color"> */}

        {/* </AppBar> */}
      </Grid>
      {loginForm && (
        <LoginForm
          handleOpen={handleLoginOpenForm}
          handleClose={handleLoginCloseForm}
          open={loginForm}
        />
      )}
    </Grid>
  );
}

export default Footer;
