import React, { Events, useState } from "react";
import "../Container.css";
import { useAuth } from "../../../Utils/AuthContext";
import { useSelector, useDispatch } from "react-redux";
import { getAllStores } from "../../../Redux/Stores/StoresActions";

import Card from "react-bootstrap/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import CardHeader from "@material-ui/core/CardHeader";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import API from "../../../Utils/API";
import Profile from "../../../Assets/profile.jpeg";
import {
  useHistory,
  useRouteMatch,
  Link,
  matchPath,
  useParams,
} from "react-router-dom";
// import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
// import { Carousel } from "react-responsive-carousel";
import Carousel from "react-bootstrap/Carousel";
import { pushSnipsData, getUserSnips } from "../../../Redux/Snips/SnipsAction";
import LoginForm from "../../DialogBox/LoginForm";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { uuid } from "../../../Utils/uuid";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import ImageGallery from "react-image-gallery";
import Moment from "react-moment";
import "moment-timezone";
import { connect } from "react-redux";
import AuthContext from "../../../Utils/AuthContext";
import SliderImage from "react-zoom-slider";
import RatingForm from "../../DialogBox/RatingForm";
import ProductCard from "./ProductCard";
import ReactPlayer from "react-player";
import { Dialog, DialogTitle } from "@material-ui/core";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination } from "swiper";
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Chip from '@material-ui/core/Chip';
import CounterInput from 'react-bootstrap-counter';
import { QuantityPicker } from "react-qty-picker";
import { makeStyles } from '@material-ui/core/styles';
import ReactStars from "react-rating-stars-component";
import ReactImageZoom from "react-image-zoom";
import ReactImageMagnify from "react-image-magnify";
import {
  FacebookShareButton,
  FacebookMessengerShareButton,
  PinterestShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";
import TwitterIcon from "../../../Assets/twitterx-stroke.png";

import {
  FacebookIcon,
  FacebookMessengerIcon,
} from "react-share";
import Slider from "react-slick";
import { withStyles } from "@material-ui/core/styles";
import { Tooltip } from "antd";
import "antd/dist/antd.css";
import PropTypes from 'prop-types';
import renderHTML from 'react-render-html';
import ShopifyStoreDiscount from "../../DialogBox/ShopifyStoreDiscount";
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop'
import environment from "../../../Utils/env";
import { EntypoCartPlus, EntypoShoppingBag } from "../../../Assets/Icons";
import { getDomainCountryCode } from "../../../service/location";
import googlePlay from "../../../Assets/google-play.png";
import appleStore from "../../../Assets/app-store.png";
import { Helmet } from "react-helmet";
import { Info, ListAlt, PlaylistAdd, PlaylistAddRounded } from "@material-ui/icons";
import { COLORS } from "../../../Utils/context/ThemeContext";
const style = {
  backButton: {
    fontSize: "25px",
    marginBottom: "10px",
    color: "#1d6042",
    cursor: "pointer",
  },
  tooltipIcon: {
    color: "#fff",
  },
};

const settings = {
  infinite: false,
  speed: 500,
  slidesToShow: 5,
  slidesToScroll: 5,
  centerMode: false,
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
}));

//const classes = useStyles();

class ProductDetails extends React.Component {
  static contextType = AuthContext;
  

  constructor(props) {
    super();
    this.state = {
      showFullscreenButton: true,
      showGalleryFullscreenButton: true,
      showPlayButton: true,
      showGalleryPlayButton: true,

      showVideo: {},
      useWindowKeyDown: true,

      loading: false,
      productData: [],
      productRating: [],
      productRatingData: [],
      sameCatProduct: [],
      sameStoreProduct: [],
      shareURL: "",
      storeData: [],
      companyData: [],
      ratingFilterCount1: 0,
      ratingFilterCount2: 0,
      ratingFilterCount3: 0,
      ratingFilterCount4: 0,
      ratingFilterCount5: 0,
      ratingFilterCountC: 0,
      ratingFilterCountM: 0,
      activeFilter: "all",
      mediaItem: [],
      productImage: [],
      displayImage: {},
      ratingForm: false,
      loginForm: false,
      discountForm: false,
      userInfo: {},
      open: false,
      key: 0,
      liked: false,
      likesLoading: false,
      likes: 0,
      snipped: false,
      previousPath: "",
      rated: false,
      followers: 0,
      variantList: [],
      selectedVariantOption1: "",
      selectedVariantOption2: "",
      selectedVariantOption3: "",
      selectedQuantity: 1,
      disabled: false,
      productId: props.match.params.productId,
      mainProductId: props.match.params.productId,
    };
    // const  params  = useParams();
    //const dispatch = useDispatch();

    // const { userInfo } = useAuth();

    // const mediaItem=[];
    this.filterClick = this.filterClick.bind(this);
    this.handleSnip = this.handleSnip.bind(this);
    this.slideClick = this.slideClick.bind(this);
    this.handleOpenForm = this.handleOpenForm.bind(this);
    this.handleCloseForm = this.handleCloseForm.bind(this);
    this.handleVariantShopifyChanage = this.handleVariantShopifyChanage.bind(this);

    //const handleOpenForm = () => this.setState({ loginForm:true});

    //const handleCloseForm = () => this.setState({ loginForm:false});
    //const userInfo  = useAuth();
  }
  handleLoginOpenForm = () => this.setState({ loginForm: true });
  handleLoginCloseForm = () => this.setState({ loginForm: false });

  handleOpenForm = () => this.setState({ ratingForm: true });
  handleCloseForm = () => this.setState({ ratingForm: false });

  handleOpenDiscountForm = () => this.setState({ discountForm: true });
  handleCloseDiscountForm = () => this.setState({ discountForm: false });

  fetchDetails = () => {
     API.getStoreById(this.props.match.params.storeId)
      .then((result) => {
        if (result != undefined) {
          // setStoreData(result)
          this.setState({ storeData: result });
          API.getStoreFollowList(this.props.match.params.storeId)
            .then((response) => {
              if (response != undefined) {
                this.setState({ followers: response.length });
              }
            })
            .catch((error) => {
              console.log(error);
            });
          API.getCompanyId(result.companyId)
            .then((result1) => {
              if (result1 != undefined) {
                this.setState({ companyData: result1 });
              }
            })
            .catch((error) => {
              console.log(error);
              console.log(error);
            });
        }
      })
      .catch((error) => {
        console.log(error);
        console.log(error);
      });
    this.fetchProductDetails();
  }
  fetchProductDetails = () => {
    this.setState({ loading: true });      
    const user = this.context;
    API.productById("gust", this.state.productId)
      .then((result) => {
        if (result !== undefined) {
          this.setState({
            productData: result,
            shareURL: `${
              getDomainCountryCode() === "CA"
                ? environment.CANADA_BACKEND_URL
                : environment.USA_BACKEND_URL
            }/Shopiads/shareMobileProduct/${this.state.productId}`,
          });
          this.setState({ selectedVariantOption1: result.variantOption1 });
          this.setState({ selectedVariantOption2: result.variantOption2 });
          this.setState({ selectedVariantOption3: result.variantOption3 });
          this.props.allLikes.allLikes.filter((e) => e.type === "Product") &&
          this.props.allLikes.allLikes.filter((e) => e.productId === result.id)
            .length > 0
            ? this.setState({ liked: true })
            : this.setState({ liked: false });
          API.getUserSnips(user?.userInfo?.email || "gust", "snip")
            .then((result1) => {
              if (result1 !== undefined) {
                result1.filter((e) => e.product.id === result.id).length > 0
                  ? this.setState({ snipped: true })
                  : this.setState({ snipped: false });
                console.log(result1);
              }
            })
            .catch((err) => {
              console.log(err);
            });
          API.getUserProductRating(user?.userInfo?.email || "gust")
            .then((response) => {
              if (response !== undefined) {
                response.filter((e) => e.id === result.id).length > 0
                  ? this.setState({ rated: true })
                  : this.setState({ rated: false });
              }
            })
            .catch((err) => {
              console.log(err);
            });

          this.setState({ likes: result.totalLikes });
          this.setState({ loading: false });

          API.productByCategoryNew({
            // TODO: provided default user name, can be removed from the backend
            userId: API.encodedUserId("gust"),
            categoryId: result.categories[0],
            marketPlace: this.props.selectedRefMarketPlace,
            pageId: 0,
            recordPerPage: 5,
            orderBy: "avgRating",
            asc: false,
          })
            .then((result1) => {
              const { content } = result1;
              if (content.length > 0) {
                //console.log('data=='+result.data);
                this.setState({ sameCatProduct: content });
              } else this.setState({ sameCatProduct: [] });
            })
            .catch((error) => {
              console.log(error);
              console.log(error);
            });
          API.getProductSearch(
            `text=${btoa(result.heading.replaceAll(/\W/g," "))}&catId=${
              result.mainCategory
            }&pageId=0&recordPerPage=${32}&marketPlace=${
              this.props.selectedRefMarketPlace
            }`
          ).then((similarProducts) => {
            const { content } = similarProducts;
            this.setState({
              sameStoreProduct: content.filter(
                (item) => item.id !== this.state.productId
              ),
            });
          });
          if (
            result.images != undefined &&
            result.images != null &&
            result.images.length > 0
          ) {
            const images = [
              {
                image: result.images[0],
                text: "",
              },
              ...(result.images.length > 1
                ? [
                    {
                      image: result.images[1],
                      text: "",
                    },
                  ]
                : []),
              ...(result.images.length > 2
                ? [
                    {
                      image: result.images[2],
                      text: "",
                    },
                  ]
                : []),
              ...(result.images.length > 3
                ? [
                    {
                      image: result.images[3],
                      text: "",
                    },
                  ]
                : []),
              ...(result.images.length > 4
                ? [
                    {
                      image: result.images[4],
                      text: "",
                    },
                  ]
                : []),
              ...(result.images.length > 5
                ? [
                    {
                      image: result.images[5],
                      text: "",
                    },
                  ]
                : []),
                ...(result.images.length > 6
                  ? [
                      {
                        image: result.images[6],
                        text: "",
                      },
                    ]
                  : []),
                  ...(result.images.length > 7
                    ? [
                        {
                          image: result.images[7],
                          text: "",
                        },
                      ]
                    : []),
                    ...(result.images.length > 8
                      ? [
                          {
                            image: result.images[8],
                            text: "",
                          },
                        ]
                      : []),
                      ...(result.images.length > 9
                        ? [
                            {
                              image: result.images[9],
                              text: "",
                            },
                          ]
                        : []),
                        ...(result.images.length > 10
                          ? [
                              {
                                image: result.images[10],
                                text: "",
                              },
                            ]
                          : []),
            ];
            console.log(images, "imageeeeeeee");
            this.setState({ productImage: images });
            this.setState({ displayImage: images[0] });
          }
        } else {
          this.setState({ productData: [] });
          this.setState({ loading: false });
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.log(error);
        // console.log(error);
      });

    API.getRatingByProduct(this.state.productId)
      .then((result) => {
        if (result != undefined) {
          console.log(result, "rrrrrrrrrrrrr");
          // setProductRating(result)
          this.setState({ productRating: result });
          //setProductRatingData(result)
          this.setState({ productRatingData: result });
          //********************************************************* */

          this.setRatingMedia(result);

          /*************************************************************** */
          const obj1 = result.filter((item) => item.rating == "1");
          // setRatingFilterCount1(obj1.length);
          this.setState({ ratingFilterCount1: obj1.length });
          const obj2 = result.filter((item) => item.rating == "2");
          this.setState({ ratingFilterCount2: obj2.length });
          const obj3 = result.filter((item) => item.rating == "3");
          this.setState({ ratingFilterCount3: obj3.length });
          const obj4 = result.filter((item) => item.rating == "4");
          this.setState({ ratingFilterCount4: obj4.length });
          const obj5 = result.filter((item) => item.rating == "5");
          this.setState({ ratingFilterCount5: obj5.length });
          const objC = result.filter(
            (item) =>
              item.comment != "" &&
              item.comment != null &&
              item.comment != undefined
          );
          this.setState({ ratingFilterCountC: objC.length });
          const objM = result.filter(
            (item) =>
              item.ratingMedia != "" &&
              item.ratingMedia != null &&
              item.ratingMedia != undefined &&
              item.ratingMedia.length > 0
          );
          this.setState({ ratingFilterCountM: objM.length });
        }
      })
      .catch((error) => {
        console.log(error);
        console.log(error);
      });
  }
  componentDidMount() {
    // const userContext = useAuth;
    const user = this.context;
    this.setState({ userInfo: user.userInfo });
    this.fetchDetails();
    API.getVariantProducts(this.state.productId)
      .then((result1) => {
        if (result1 != undefined) {
          this.setState({ variantList: result1 });
        }
      })
      .catch((error) => {
        console.log(error);
        console.log(error);
      });
    window.scrollTo(0, 0)
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.productId !== this.state.productId) {
    this.fetchProductDetails();      
    }
  }

  handleSnip = (dataObj) => {
    console.log("user info>>>>>>>>>>>>>>>>", this.state.userInfo);
    if (!this.state.userInfo?.email) this.setState({ loginForm: true });
    else {
      console.log("all snip>>>>>>>>>>", this.props.allSnips.allSnips);
      console.log("dataObj snip>>>>>>>>>>", dataObj);
      const obj = this.props.allSnips.allSnips.filter(
        (item) => item?.product != null && item.product.id === dataObj.id
      );
      console.log("all obj>>>>>>>>>>", obj);
      if (obj != null && obj != undefined && obj != "") {
        toast.error("Product already in snip..");
      } else {
        API.getStoreById(dataObj.storeId)
          .then((result) => {
            if (result != undefined) {
              var snapList = [];
              let snipBody = {
                id: uuid(),
                userId: this.state.userInfo?.email,
                product: dataObj,
                activityType: "snip",
                storeId: dataObj.storeId,
                storeName: result.storeName,
                storeCode: result.code,
                productId: dataObj.id,
                type: "Product",
              };
              snapList.push(snipBody);
              API.addSnip(this.state.userInfo?.email, snipBody);
              this.props.pushSnips(snipBody);
              this.setState({ snipped: true });
            }
          })
          .catch((error) => {
            console.log(error);
            console.log(error);
          });
      }
    }
  };

  handleBuy = async (vendorUrl,isShopifyProduct,varientId) => {
    console.log("Handle Verify Changes Buy Now :::::",vendorUrl, isShopifyProduct, varientId);
    if (!this.state.userInfo?.email) this.setState({ loginForm: true });
    else {
      API.productBuyNow(
        this.state.userInfo?.email,
        this.state.productId
      );
      if(isShopifyProduct=="Y") {
        console.log("this.state.storeData.websiteUrl?????????????",this.state.storeData.websiteUrl);
      const shopifyToken= await API.getShopifyShopToken(this.state.storeData.websiteUrl);
      let storeFrontToken="";
      if(shopifyToken!=undefined&&shopifyToken!=null&&shopifyToken!="")
      {
        storeFrontToken=shopifyToken.storefrontToken
      }
        const queryParams = API.generateQueryString({
          access_token: storeFrontToken ? storeFrontToken : null,
          "checkout[email]": this.state.userInfo?.email,
          "attributes[utm_source]": "shopiads",
        });
        var catUrl =
          "https://" +
          this.state.storeData.websiteUrl +
          "/cart/" +
          varientId +
          ":" +
          this.state.selectedQuantity +
          `?${queryParams}`;
        // var catUrl="http://localhost:3000/redirectToCart/"+this.props.match.params.productId+"/"+this.state.userInfo?.email;
        console.log("ValidateUrl :::::",catUrl)
        //window.location.replace(catUrl);
        window.open(catUrl, '_blank');
      } else {
        //window.location.replace(vendorUrl+"?utm_source=shopiads");
        window.open(vendorUrl+"?utm_source=shopiads", '_blank');
      }
     // window.location.replace(vendorUrl+"?utm_source=shopiads");
     //window.open(vendorUrl+"?utm_source=shopiads", '_blank');
    }
  };

  setRatingMedia(data) {
    const mediaItem = [];
    console.log("this.state.productRating>>>>>>>>>>>>>>>>>>>>>", data);
    data.map((item, index) => {
      if (
        item.ratingMedia != null &&
        item.ratingMedia != undefined &&
        item.ratingMedia.length > 0
      ) {
        const images = [
          // {
          //   original: "",
          //   thumbnail: "",
          // },
          {
            original: this.isVideo(item.ratingMedia[0])
              ? "https://storage.googleapis.com/assets.shopiads.ca/imagesProd/RatingVideoImage/icons-play.png"
              : item.ratingMedia[0],
            thumbnail: this.isVideo(item.ratingMedia[0])
              ? "https://storage.googleapis.com/assets.shopiads.ca/imagesProd/RatingVideoImage/icons-play.png"
              : item.ratingMedia[0],
            ...(this.isVideo(item.ratingMedia[0]) && {
              embedUrl: item.ratingMedia[0],
            }),
            ...(this.isVideo(item.ratingMedia[0]) && {
              renderItem: this._renderVideo.bind(this),
            }),
            //embedUrl: 'https://storage.googleapis.com/assets.shopiads.ca/imagesProd/RatingVideoImage/Mississauga%2084/2e2e23cf31b6a31b006cf35ac32bd18f.mp4',
            //description:"viedeo",
            //renderItem: this._renderVideo.bind(this)
          },
          ...(item.ratingMedia.length > 1
            ? [
                {
                  original: this.isVideo(item.ratingMedia[1])
                    ? "https://storage.googleapis.com/assets.shopiads.ca/imagesProd/RatingVideoImage/icons-play.png"
                    : item.ratingMedia[1],
                  thumbnail: this.isVideo(item.ratingMedia[1])
                    ? "https://storage.googleapis.com/assets.shopiads.ca/imagesProd/RatingVideoImage/icons-play.png"
                    : item.ratingMedia[1],
                  ...(this.isVideo(item.ratingMedia[1]) && {
                    embedUrl: item.ratingMedia[1],
                  }),
                  ...(this.isVideo(item.ratingMedia[1]) && {
                    renderItem: this._renderVideo.bind(this),
                  }),
                },
              ]
            : []),
          ...(item.ratingMedia.length > 2
            ? [
                {
                  original: this.isVideo(item.ratingMedia[2])
                    ? "https://storage.googleapis.com/assets.shopiads.ca/imagesProd/RatingVideoImage/icons-play.png"
                    : item.ratingMedia[2],
                  thumbnail: this.isVideo(item.ratingMedia[2])
                    ? "https://storage.googleapis.com/assets.shopiads.ca/imagesProd/RatingVideoImage/icons-play.png"
                    : item.ratingMedia[2],
                  ...(this.isVideo(item.ratingMedia[2]) && {
                    embedUrl: item.ratingMedia[2],
                  }),
                  ...(this.isVideo(item.ratingMedia[2]) && {
                    renderItem: this._renderVideo.bind(this),
                  }),
                },
              ]
            : []),
          ...(item.ratingMedia.length > 3
            ? [
                {
                  original: this.isVideo(item.ratingMedia[3])
                    ? "https://storage.googleapis.com/assets.shopiads.ca/imagesProd/RatingVideoImage/icons-play.png"
                    : item.ratingMedia[3],
                  thumbnail: this.isVideo(item.ratingMedia[3])
                    ? "https://storage.googleapis.com/assets.shopiads.ca/imagesProd/RatingVideoImage/icons-play.png"
                    : item.ratingMedia[3],
                  ...(this.isVideo(item.ratingMedia[3]) && {
                    embedUrl: item.ratingMedia[3],
                  }),
                  ...(this.isVideo(item.ratingMedia[3]) && {
                    renderItem: this._renderVideo.bind(this),
                  }),
                },
              ]
            : []),
        ];
        mediaItem[index] = images;
      }
    });
    this.setState({ mediaItem: mediaItem });
  }
  isVideo(reviewUrl) {
    const videos = ["mp4", "3gp", "ogg", "mov", "wmv", "flv", "avi", "mkv"];
    const url = new URL(reviewUrl);
    const extension = url.pathname.split(/\.(?=[^\.]+$)/)[1];
    console.log("extension>>>>>>>>>>>>>>>>>>>>>>>>>>>", extension);
    let flag = false;
    if (videos.includes(extension)) {
      flag = true;
    }
    console.log("isVideo>>>>>>>>>>>>>>>>>>>>>>>>>>>", flag);
    return flag;
  }

  slideClick = (newValue) => {
    this._resetVideo();
    // console.log(newValue);
    var elements = document.getElementsByClassName(
      "image-gallery-slide-wrapper"
    );
    for (var i in elements) {
      if (elements.hasOwnProperty(i)) {
        elements[i].className = "image-gallery-slide-show";
        // elements.insertRule(".display {block}", 0);
      }
    }
  };

  onImageCilck = (index) => {
    this.setState({ open: !this.state.open });
    this.setState({ key: index });
  };

  imageGallery(items, index) {
    return items.map((item) => (
      <div
        className="ml-4 gallery-items"
        onClick={this.onImageCilck.bind(this, index)}
      >
        {item.embedUrl ? (
          <ReactPlayer
            playIcon
            width="100px"
            height="50px"
            url={item.embedUrl}
          />
        ) : (
          <img style={{ width: "50px", height: "50px" }} src={item.thumbnail} />
        )}
      </div>
    ));
  }

  makeLikes(newValue) {
    if (!this.state.userInfo?.email) this.setState({ loginForm: true });
    else {
      this.setState({ likesLoading: true });
      if (
        this.props.allLikes.allLikes !== undefined &&
        this.props.allLikes.allLikes.filter((e) => e.type === "Product") &&
        this.props.allLikes.allLikes.filter((e) => e.productId === newValue)
          .length === 0 &&
        !this.props.loading.loading &&
        !this.state.likesLoading
      ) {
        API.makeLikes(this.state.userInfo?.email, newValue, "product")
          .then((result1) => {
            if (result1 != undefined) {
              this.props.getUserSnips(this.state.userInfo?.email, "like");
              this.setState({ likesLoading: false });
              API.productById("gust", newValue)
                .then((result) => {
                  if (result !== undefined) {
                    this.setState({ likes: result.totalLikes });
                    this.setState({ liked: true });
                  }
                })
                .catch((error) => {
                  this.setState({ loading: false });
                  console.log(error);
                  // console.log(error);
                });
            } else {
              this.setState({ likesLoading: false });
            }
          })
          .catch((error) => {
            console.log(error);
            this.setState({ likesLoading: false });
          });
      } else if(!this.props.loading.loading && !this.state.likesLoading && this.props.allLikes.allLikes != undefined && this.props.allLikes.allLikes.filter((e) => e.productId == newValue && e.activityType == "like").length>0) {
        const productId =
          this.props.allLikes.allLikes !== undefined &&
          this.props.allLikes.allLikes.filter(
            (e) => e.productId === newValue
          )[0].id;
        API.makeUnLikes(
          this.state.userInfo?.email,
          newValue,
          "product",
          productId
        )
          .then((result1) => {
            if (result1 !== undefined) {
              this.props.getUserSnips(this.state.userInfo?.email, "like");
              this.setState({ likesLoading: false });
              API.productById("gust", newValue)
                .then((result) => {
                  if (result !== undefined) {
                    this.setState({ likes: result.totalLikes });
                    this.setState({ liked: false });
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            } else {
              this.setState({ likesLoading: false });
            }
          })
          .catch((error) => {
            console.log(error);
            this.setState({ likesLoading: false });
          });
      }
    }
  }

  filterClick(value) {
    let obj = [];
    // setActiveFilter(value);
    this.setState({ activeFilter: value });
    if (value == "all") {
      this.setState({ productRating: this.state.productRatingData });
      this.setRatingMedia(this.state.productRatingData);
      // setProductRating(productRatingData);
    } else if (value == "c") {
      console.log("filter cond>>>>>>>>>>>>>>", value);
      obj = this.state.productRatingData.filter(
        (item) =>
          item.comment != "" &&
          item.comment != null &&
          item.comment != undefined
      );
      console.log("filter Value>>>>>>>>>>>>>>", obj);
      this.setState({ productRating: obj });
      this.setRatingMedia(obj);
    } else if (value == "m") {
      console.log("filter cond>>>>>>>>>>>>>>", value);
      obj = this.state.productRatingData.filter(
        (item) =>
          item.ratingMedia != "" &&
          item.ratingMedia != null &&
          item.ratingMedia != undefined &&
          item.ratingMedia.length > 0
      );
      console.log("filter Value>>>>>>>>>>>>>>", obj);
      this.setState({ productRating: obj });
      this.setRatingMedia(obj);
    } else {
      console.log("filter cond>>>>>>>>>>>>>>", value);
      obj = this.state.productRatingData.filter((item) => item.rating == value);
      console.log("filter Value>>>>>>>>>>>>>>", obj);
      this.setState({ productRating: obj });
      this.setRatingMedia(obj);
    }
  }

  _resetVideo() {
    console.log("viedo reset>>>>>>>>>>>>>>>");
    this.setState({ showVideo: {} });

    if (this.state.showPlayButton) {
      this.setState({ showGalleryPlayButton: true });
    }

    if (this.state.showFullscreenButton) {
      this.setState({ showGalleryFullscreenButton: true });
    }
  }

  
  _toggleShowVideo(url) {
    this.state.showVideo[url] = !Boolean(this.state.showVideo[url]);
    this.setState({
      showVideo: this.state.showVideo,
    });

    if (this.state.showVideo[url]) {
      if (this.state.showPlayButton) {
        this.setState({ showGalleryPlayButton: false });
      }

      if (this.state.showFullscreenButton) {
        this.setState({ showGalleryFullscreenButton: false });
      }
    }
  }

  _renderVideo(item) {
    console.log("item>>>>>>>>>>>>>>>>>>>", item);
    console.log("showVideo>>>>>>>>>>>>>>>>>>>", this.state.showVideo);
    this._toggleShowVideo.bind(this, item.embedUrl);
    return (
      <div>
        {this.state.showVideo[item.embedUrl] ? (
          <div className="video-wrapper">
            <a
              className="close-video"
              onClick={this._toggleShowVideo.bind(this, item.embedUrl)}
            ></a>
            {/*  <iframe
                  width='560'
                  height='315'
                  src={item.embedUrl}
                  frameBorder='0'
                  allowFullScreen
                >
              </iframe>*/}
            <video width="560" height="315" controls autoPlay muted>
              <source
                src={item.embedUrl}
                type={"video/" + item.embedUrl.split(/\.(?=[^\.]+$)/)[1]}
              />
            </video>
          </div>
        ) : (
          <a onClick={this._toggleShowVideo.bind(this, item.embedUrl)}>
            <div className="play-button"></div>
            <img className="image-gallery-image" src={item.original} />
            {item.description && (
              <span
                className="image-gallery-description"
                style={{ right: "0", left: "initial" }}
              >
                {renderHTML(item.description)}
              </span>
            )}
          </a>
        )}
      </div>
    );
  }

  viewStoreClick(storeId) {
    this.props.history.push(`/storeDetails/` + storeId);
  }

  viewAllCatProductClick(catId) {
    window.scrollTo(0, 0);
    this.props.history.push(`/product/` + catId);
  }

  productCardClick(newValue, productId) {
    this.props.history.push(`/productDetail/` + newValue + "/" + productId);
  }

  /* ratingClick(newValue){
   // history.push(`/storeDetails/` + newValue);
   if (!userInfo?.email) setLoginForm(true);
   else
     setRatingForm(true);
   // console.log("Under Development..............")
 
 };*/

  ratingClick(newValue) {
    // history.push(`/storeDetails/` + newValue);
    if (!this.state.userInfo?.email) this.setState({ loginForm: true });
    else this.setState({ ratingForm: true });
    // console.log("Under Development..............")
  }

  returnClick(newValue) {
    window.scrollTo(0, 0);
    this.props.history.push(newValue);
  }

  imageClick(item) {
    this.setState({ displayImage: item });
  }

  toUpperCaseFilter = (d) => {
    return d.toUpperCase();
  };

  handleVariantShopifyChanage= (e,index,itemValue) => {
    if(itemValue!=undefined&&itemValue!=null&&itemValue!="-1")
    {
      let op1=this.state.selectedVariantOption1
      let op2=this.state.selectedVariantOption2
      let op3=this.state.selectedVariantOption3
      let productData=[];

      if(index==0)
      {
      this.setState({ selectedVariantOption1: itemValue });
      op1=itemValue
      
      }
      if(index==1)
      {
      this.setState({ selectedVariantOption2: itemValue });
      op2=itemValue
      }
      if(index==2)
      {
      this.setState({ selectedVariantOption3: itemValue });
      op3=itemValue
      }
      productData = this.state.variantList.find(function (item) {
        return item.variantOption1 == op1 && item.variantOption2 == op2 && item.variantOption3 == op3;
      });
      console.log({ productData })
      if (productData) {
        this.setState({ productId: productData.id })        
      } else {
        this.setState({ productId: this.state.mainProductId })        
    
      }
    }
  }

   followStore = (newValue) => {
    if (!this.state.userInfo?.email) this.setState({ loginForm: true });
    else
    {
    API.followStore(this.state.userInfo?.email, newValue, "follow")
      .then((result) => {
        if (result != undefined) {
          this.setState({ disabled: !this.state.disabled });
          
          //setCollectionProduct(result);
        } else {
          //setCollectionProduct([]);
        }
      })
      .catch((error) => {
        //setLoading(false);
        console.log(error);
      });
    }
  };
   openDiscountPage = () => {
    this.setState({ discountForm: true });
    
  };

  render() {
    const { classes } = this.props;
    SwiperCore.use([Navigation, Pagination]);
       if (window.innerWidth < 768) {
         return (
           <div className="container-fluid my-5 pt-5">
             <Helmet>
               <title>{this.state.productData?.heading}</title>
               <link
                 id="favicon"
                 rel="icon"
                 type="image/x-icon"
                 href={
                   this.state.productData?.images != undefined &&
                   this.state.productData?.images != "" &&
                   this.state.productData?.images != null &&
                   this.state.productData?.images.length > 0
                     ? this.state.productData?.images[0] +
                       "?v=" +
                       Math.floor(Math.random() * (100000000 - 1 + 1) + 1) +
                       "&amp;width=640"
                     : "https://storage.googleapis.com/assets.shopiads.ca/imagesProd/No-image-found.jpg" +
                       "?v=" +
                       Math.floor(Math.random() * (100000000 - 1 + 1) + 1) +
                       "&amp;width=640"
                 }
               />
               <meta
                 name="description"
                 content={"$" + this.state.productData?.offerPrice}
               />
               <meta
                 name="og-title"
                 property="og:title"
                 content={this.state.productData?.heading}
               />
               <meta
                 name="og:description"
                 property="og:description"
                 content={this.state.productData?.offerPrice}
               />
               <meta
                 name="og:image"
                 property="og:image"
                 content={
                   this.state.productData?.images != undefined &&
                   this.state.productData?.images != "" &&
                   this.state.productData?.images != null &&
                   this.state.productData?.images.length > 0
                     ? this.state.productData?.images[0]
                     : "https://storage.googleapis.com/assets.shopiads.ca/imagesProd/No-image-found.jpg" +
                       "?v=" +
                       Math.floor(Math.random() * (100000000 - 1 + 1) + 1) +
                       "&amp;width=640"
                 }
               />
               <meta
                 name="og:url"
                 property="og:url"
                 content={this.state.shareURL}
               />
               <meta
                 name="twitter:card"
                 property="twitter:card"
                 content="summary_large_image"
               />
               <meta
                 id="twitter:site"
                 property="twitter:site"
                 content="@ShopiAds"
               />
               <meta
                 id="twitter:title"
                 property="twitter:title"
                 content={this.state.productData?.heading}
               />
               <meta
                 id="twitter:description"
                 property="twitter:description"
                 content={this.state.productData?.offerPrice}
               />
               <meta
                 id="twitter:image"
                 property="twitter:image"
                 content={
                   this.state.productData?.images != undefined &&
                   this.state.productData?.images != "" &&
                   this.state.productData?.images != null &&
                   this.state.productData?.images.length > 0
                     ? this.state.productData?.images[0]
                     : "https://storage.googleapis.com/assets.shopiads.ca/imagesProd/No-image-found.jpg" +
                       "?v=" +
                       Math.floor(Math.random() * (100000000 - 1 + 1) + 1) +
                       "&amp;width=640"
                 }
               />
             </Helmet>

             <div className="row">
               <div className="col-md-12">
                 <div
                   class="vertical-stack product"
                   data-page-type="universal-link-product"
                 >
                   <div class="product-card">
                     <div class="product-card__image-wrapper">
                       <img
                         class="product-card__image"
                         src={
                           this.state.productData?.images != undefined &&
                           this.state.productData?.images != "" &&
                           this.state.productData?.images != null &&
                           this.state.productData?.images.length > 0
                             ? this.state.productData?.images[0]
                             : "https://storage.googleapis.com/assets.shopiads.ca/imagesProd/No-image-found.jpg" +
                               "?v=" +
                               Math.floor(
                                 Math.random() * (100000000 - 1 + 1) + 1
                               ) +
                               "&amp;width=640"
                         }
                       />
                     </div>

                     <p class="product-card__title">
                       {this.state.productData?.heading}
                     </p>
                     <p class="product-card__description">
                       ${this.state.productData?.offerPrice}
                     </p>
                   </div>

                   <div class="universal-link-product-app-details-desktop horizontal-stack">
                     <div class="horizontal-stack universal-link-product-store-logos">
                       <a
                         class="universal-link-product-app-store-link js-interaction-button"
                         data-interaction-type="ios-download"
                         href="https://apps.apple.com/us/app/shopiads/id1601835714"
                       >
                         <img
                           class="image"
                           sizes="100vw"
                           src={appleStore}
                           alt=""
                         />
                       </a>
                       <a
                         class="universal-link-product-play-store-link js-interaction-button"
                         data-interaction-type="android-download"
                         href="https://play.google.com/store/apps/details?id=com.apcod.shopiads"
                       >
                         <img
                           class="image"
                           sizes="100vw"
                           src={googlePlay}
                           alt=""
                         />
                       </a>{" "}
                     </div>
                   </div>
                 </div>
               </div>
             </div>
           </div>
         );
       }
    return (
      <>
        <div className="container-fluid mt-5">
          <Backdrop
            style={{
              backgroundColor: "rgba(229,229,229, 0.1)",
              position: "absolute",
              zIndex: 9999,
            }}
            open={this.state.loading}
          >
            <CircularProgress color="inherit" style={{ color: "#1d6042" }} />
          </Backdrop>
          <div className="row">
            <div className="col-md-10 mx-auto bg-white px-0">
              <i
                style={{ ...style.backButton, position: "relative" }}
                className="fa fa-arrow-circle-left"
                aria-hidden="true"
                title="Back to Login"
                onClick={() => this.props.history.goBack()}
              ></i>
              <div className="row">
                <div className="col-md-5">
                  <div className="sslider-wrapper">
                    {/* <Carousel>
  
                        <div>
                          <img src={this.state.productData.images != undefined && this.state.productData.images != null && this.state.productData.images.length > 0 ? this.state.productData.images[0] : ""} />
  
                        </div>
                        <div>
                          <img src={this.state.productData.images != undefined && this.state.productData.images != null && this.state.productData.images.length > 1 ? this.state.productData.images[1] : ""} />
  
                        </div>
  
                        <div>
                          <img src={this.state.productData.images != undefined && this.state.productData.images != null && this.state.productData.images.length > 2 ? this.state.productData.images[2] : ""} />
  
                        </div>
  
                        <div>
                          <img src={this.state.productData.images != undefined && this.state.productData.images != null && this.state.productData.images.length > 3 ? this.state.productData.images[3] : ""} />
  
                        </div>
  
                     </Carousel>*/}
                    {this.state.productImage != undefined &&
                    this.state.productImage != null &&
                    this.state.productImage.length > 0 ? (
                      <div>
                        <div>
                          <ReactImageMagnify
                            {...{
                              smallImage: {
                                alt: "Wristwatch by Ted Baker London",
                                src: this.state.displayImage.image,
                                width: 400,
                                height: 500,
                              },
                              largeImage: {
                                src: this.state.displayImage.image,
                                width: 1000,
                                height: 1200,
                              },
                              enlargedImageContainerDimensions: {
                                width: "200%",
                                height: "100%",
                              },
                              enlargedImageContainerStyle: {
                                zIndex: 1000,
                              },
                              shouldUsePositiveSpaceLens: true,
                            }}
                          />
                        </div>
                      </div>
                    ) : (
                      // </Swiper>
                      // <SliderImage
                      //   data={this.state.productImage}
                      //   width="500px"
                      //   showDescription={true}
                      //   direction="right"
                      // />
                      ""
                    )}
                  </div>
                  <div className="mt-3 smallImages proImage-scroll">
                    {this.state.productImage !== undefined &&
                      this.state.productImage.map((item, index) => (
                        <div className="mr-3" style={{ cursor: "pointer" }}>
                          <img
                            style={{ width: "80px", height: "100px" }}
                            src={item.image}
                            onClick={this.imageClick.bind(this, item)}
                          />
                        </div>
                      ))}
                  </div>
                  <div className="d-flex align-items-center">
                    <div>Share</div>
                    <div className="socail-icons ml-3">
                      <FacebookShareButton
                        url={this.state.shareURL}
                        quote={"Hey checkout this offer"}
                      >
                        <FacebookIcon
                          size={28}
                          logoFillColor="white"
                          round={true}
                        ></FacebookIcon>
                      </FacebookShareButton>
                      <FacebookMessengerShareButton
                        appId="6833289606700416"
                        url={this.state.shareURL}
                      >
                        <FacebookMessengerIcon
                          size={28}
                          logoFillColor="white"
                          round={true}
                        ></FacebookMessengerIcon>
                      </FacebookMessengerShareButton>
                      <TwitterShareButton url={this.state.shareURL}>
                        <img
                          src={TwitterIcon}
                          style={{ height: 28, widows: 28, borderWidth: "1px" }}
                          sizes="28"
                        />
                      </TwitterShareButton>
                      <WhatsappShareButton url={this.state.shareURL}>
                        <WhatsappIcon size={28} round={true} />
                      </WhatsappShareButton>
                      {/* <PinterestShareButton
                          url={localStorage.getItem("connectSite")=="CA"?`${environment.CANADA_BASE_URL}/Shopiads/shareProduct/${this.state.productData.id}`:`${environment.USA_BASE_URL}/Shopiads/shareProduct/${this.state.productData.id}`}
                          media={`${
                            this.state.productImage.length > 0 &&
                            this.state.productImage[0].image
                          }`}
                        >
                          <PinterestIcon
                            size={28}
                            logoFillColor="white"
                            round={true}
                          ></PinterestIcon>
                        </PinterestShareButton> */}
                    </div>
                    {this.state.liked ? (
                      <div
                        onClick={() => {
                          this.makeLikes(this.state.productData.id);
                        }}
                        style={{ cursor: "pointer" }}
                        class="fav ml-5 d-flex align-items-center"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16.69"
                          viewBox="0 0 28 25.69"
                        >
                          <path
                            id="ic_favorite_border_24px"
                            d="M22.3,3A8.384,8.384,0,0,0,16,5.926,8.384,8.384,0,0,0,9.7,3,7.625,7.625,0,0,0,2,10.7c0,5.292,4.76,9.6,11.97,16.156L16,28.69l2.03-1.848C25.24,20.3,30,15.992,30,10.7A7.625,7.625,0,0,0,22.3,3ZM16.14,24.77l-.14.14-.14-.14c-6.664-6.034.14,4.046.14,0,0-2.8-2.94,0-.14,0,2.156,0-1.9-17.584-1.162-15.666L16.14,25.577c.728-1.918,5.72-6.446,7.876-6.446,2.8,0,2.743-10.627,2.743-7.827,0,3.395-3.578,6.217-8.729,10.927C17.422,22.788,17.213,23.8,16.14,24.77Z"
                            transform="translate(-2 -3)"
                            fill="#1d6042"
                          />
                        </svg>
                        <div className="rating">
                          {this.state.likes > 0
                            ? " Likes (" +
                              Intl.NumberFormat("en-US", {
                                notation: "compact",
                                maximumFractionDigits: 1,
                              }).format(this.state.likes) +
                              ")"
                            : " Likes (0)"}
                        </div>
                      </div>
                    ) : (
                      <div
                        onClick={() => {
                          this.makeLikes(this.state.productData.id);
                        }}
                        style={{ cursor: "pointer" }}
                        class="fav ml-5 d-flex align-items-center"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="#1d6042"
                          className="bi bi-heart"
                          viewBox="0 0 16 16"
                        >
                          <path d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01L8 2.748zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143c.06.055.119.112.176.171a3.12 3.12 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15z"></path>
                        </svg>
                        <div className="rating">
                          {this.state.likes > 0
                            ? " Likes (" +
                              Intl.NumberFormat("en-US", {
                                notation: "compact",
                                maximumFractionDigits: 1,
                              }).format(this.state.likes) +
                              ")"
                            : " Likes (0)"}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-7">
                  <div className="product-deatails-wrapper ml-5">
                    <div className="product-name">
                      {this.state.productData.heading}
                    </div>
                    <div className="d-flex align-items-center mt-2">
                      <div className="rat">
                        {this.state.productData.avgRating != undefined &&
                        Number(this.state.productData.avgRating) > 0
                          ? this.state.productData.avgRating
                          : 0}{" "}
                        Rating
                      </div>
                      <div className="rating-star ml-2">
                        <ReactStars
                          key={`stars_${Number(
                            this.state.productData.avgRating
                          )}`}
                          count={5}
                          size={24}
                          isHalf={true}
                          edit={false}
                          value={Number(this.state.productData.avgRating)}
                          activeColor="#ffd700"
                        />
                      </div>
                      {this.state.rated ? (
                        <Button
                          variant="contained"
                          disabled
                          // color="primary"
                          className="ml-4"
                          // style={{ width: "250px" }}
                          onClick={this.ratingClick.bind(
                            this,
                            this.state.productData.storeId
                          )}
                        >
                          Rate Product
                        </Button>
                      ) : (
                        <Button
                          variant="contained"
                          color="primary"
                          className="ml-4"
                          // style={{ width: "250px" }}
                          onClick={this.ratingClick.bind(
                            this,
                            this.state.productData.storeId
                          )}
                        >
                          Rate Product
                        </Button>
                      )}
                    </div>
                    <div className="bg-price mt-3">
                      <div className="price">
                        ${this.state.productData.offerPrice}
                      </div>
                    </div>
                    {this.state.productData.isShopifyProduct === "Y" &&
                      this.state.variantList != undefined &&
                      this.state.variantList.length > 0 && (
                        <div className="mt-4">
                          {this.state.productData.attribute != undefined &&
                          this.state.productData.attribute != null &&
                          this.state.productData.attribute.length > 0
                            ? this.state.productData.attribute.map(
                                (item, index) => {
                                  return item.value != "Default Title" ? (
                                    <div className="mb-2 row">
                                      <div className="mt-3 col col-2">
                                        {item.column} :
                                      </div>
                                      <div>
                                        {item.value
                                          .split(",")
                                          .map((subItem, index1) => (
                                            <Button
                                              variant={
                                                index == 0
                                                  ? this.state
                                                      .selectedVariantOption1 ==
                                                    subItem
                                                    ? "contained"
                                                    : "outlined"
                                                  : index == 1
                                                  ? this.state
                                                      .selectedVariantOption2 ==
                                                    subItem
                                                    ? "contained"
                                                    : "outlined"
                                                  : index == 2
                                                  ? this.state
                                                      .selectedVariantOption3 ==
                                                    subItem
                                                    ? "contained"
                                                    : "outlined"
                                                  : ""
                                              }
                                              color={
                                                index == 0
                                                  ? this.state
                                                      .selectedVariantOption1 ==
                                                    subItem
                                                    ? "primary"
                                                    : ""
                                                  : index == 1
                                                  ? this.state
                                                      .selectedVariantOption2 ==
                                                    subItem
                                                    ? "primary"
                                                    : ""
                                                  : index == 2
                                                  ? this.state
                                                      .selectedVariantOption3 ==
                                                    subItem
                                                    ? "primary"
                                                    : ""
                                                  : ""
                                              }
                                              size="small"
                                              // className="diag"
                                              style={{ margin: "10px" }}
                                              onClick={(e) =>
                                                this.handleVariantShopifyChanage(
                                                  e,
                                                  index,
                                                  subItem
                                                )
                                              }
                                            >
                                              {subItem}
                                            </Button>
                                          ))}
                                      </div>
                                    </div>
                                  ) : (
                                    ""
                                  );
                                }
                              )
                            : ""}
                        </div>
                      )}
                    {this.state.productData.isShopifyProduct == "Y" ? (
                      <div className="mt-1 row">
                        <div className="mt-1 col-2">Quantity :</div>
                        <div className="row pl-2">
                          <Button
                            variant="outlined"
                            color="primary"
                            size="small"
                            startIcon={
                              <i
                                className="fa fa-minus"
                                aria-hidden="true"
                                style={{ marginLeft: 5 }}
                              ></i>
                            }
                            style={{ height: "30px" }}
                            className="ml-3"
                            onClick={(e) => {
                              this.state.selectedQuantity > 1
                                ? this.setState({
                                    selectedQuantity:
                                      this.state.selectedQuantity - 1,
                                  })
                                : this.setState({ selectedQuantity: 1 });
                            }}
                          ></Button>
                          <h5 className="ml-2 mt-1">
                            {" "}
                            {this.state.selectedQuantity}{" "}
                          </h5>
                          <Button
                            variant="outlined"
                            startIcon={
                              <i
                                className="fa fa-plus"
                                aria-hidden="true"
                                style={{ marginLeft: 5 }}
                              ></i>
                            }
                            color="primary"
                            size="small"
                            style={{ height: "30px" }}
                            className="ml-2 p-0"
                            onClick={(e) => {
                              this.setState({
                                selectedQuantity:
                                  this.state.selectedQuantity + 1,
                              });
                            }}
                          ></Button>
                        </div>
                        {/* <QuantityPicker 
                               value={this.state.selectedQuantity} 
                               min={1} max={50} 
                               onChange={ (value) => { this.setState({ selectedQuantity: value }) } } 
                               style={{borderWidth:0}}
                            /> */}
                      </div>
                    ) : (
                      ""
                    )}

                    <div className="mt-4 w-50">
                      Sold & Fulfilled By :
                      <span className="ml-2">
                        {" "}
                        {this.state.productData.brand != "" &&
                        this.state.productData.brand != null
                          ? this.state.productData.brand
                          : this.state.productData.webStoreName}{" "}
                      </span>
                    </div>
                    <table
                      className="table table-borderless mt-2 mb-5 w-100 product-desc-table"
                      style={{ marginLeft: "-12px" }}
                    >
                      {this.state.productData.isShopifyProduct != "Y" ? (
                        <tr>
                          <td>Shipping { " : "}
                          {this.state.productData.shippingType ==
                            "Free Shipping" ? (
                              <img
                                src="https://storage.googleapis.com/assets.shopiads.ca/imagesProd/freeShippingIcon.png"
                                width="25"
                                height="15"
                                class="_2K1bHr"
                              />
                            ) : (
                              ""
                            )}{" "}
                            {this.state.productData.shippingType}
                            <br />
                            {
                              this.state.productData.shippingInstructions && 
                              <small className="ml-4 pl-1">
                                {this.state.productData.shippingInstructions}
                              </small>
                            }
                          </td>
                        </tr>
                      ) : (
                        ""
                      )}
                      {this.state.productData.isShopifyProduct != "Y" ? (
                        <tr>
                          <td>{`Brand : ${this.state.productData.brand}`}</td>
                        </tr>
                      ) : (
                        ""
                      )}

                      {this.state.productData.isShopifyProduct != "Y" &&
                      this.state.productData.attribute != undefined &&
                      this.state.productData.attribute != null &&
                      this.state.productData.attribute.length > 0
                        ? this.state.productData.attribute.map((item, index) =>
                            item.value != "Default Title" ? (
                              <tr>
                                <td>{item.column}</td>
                                <td>{item.value}</td>
                              </tr>
                            ) : (
                              ""
                            )
                          )
                        : ""}

                      <tr>
                        <td>
                          Return Policy
                          <Tooltip
                            placement="bottom"
                            color={"white"}
                            title={
                              <React.Fragment>
                                <li
                                  className="return-inner"
                                  style={{ color: "gray" }}
                                >
                                  ●{" "}
                                  {this.state.storeData.returnPolicy !=
                                    undefined &&
                                  this.state.storeData.returnPolicy != null
                                    ? renderHTML(
                                        this.state.storeData.returnPolicy
                                      )
                                    : ""}
                                </li>
                                <b
                                  className="d-flex justify-content-end know-more-link"
                                  onClick={this.returnClick.bind(
                                    this,
                                    `/Returns/${this.state.productData.storeId}`
                                  )}
                                >
                                  Know More
                                </b>
                              </React.Fragment>
                            }
                          >
                            <Info style={{ color: "#1d6042" ,marginLeft:"10px"}} fontSize="small"/>
                          </Tooltip>
                        </td>
                      </tr>
                    </table>
                  <div className="product-deatails-wrapper">
                  {/* <div className="button-wrapper mt-3"> */}
                      <div class="grid">
                        <div class="row mt-4">
                          {this.state.snipped ? (
                            <Button
                              variant="contained"
                              disabled
                              startIcon={<ListAlt />}
                              // style={{ width: "250px" }}
                              onClick={this.handleSnip.bind(
                                this,
                                this.state.productData
                              )}
                            >
                              In List
                            </Button>
                          ) : (
                            <Button
                              variant="outlined"
                              color="primary"
                              className="ml-4"
                              startIcon={
                                <PlaylistAddRounded fontSize="large" />
                              }
                              style={{ color: "#1d6042" }}
                              onClick={this.handleSnip.bind(
                                this,
                                this.state.productData
                              )}
                            >
                              {/* <PlaylistAddRounded fontSize="large" /> */}
                              Add To List
                            </Button>
                          )}
                          {this.state.productData.isShopifyProduct != "Y" ||
                          this.state.productData.shopifyInventory > 0 ? (
                            <Button
                              variant="contained"
                              color="primary"
                              className="ml-4"
                              startIcon={<EntypoShoppingBag />}
                              onClick={this.handleBuy.bind(
                                this,
                                this.state.productData.vendorUrl,
                                this.state.productData.isShopifyProduct,
                                this.state.productData.shopifyVarientId
                              )}
                            >
                              Buy Now
                            </Button>
                          ) : (
                            <Button
                              variant="contained"
                              color="default"
                              className="ml-4"
                              startIcon={<EntypoShoppingBag />}
                            >
                              Sold Out
                            </Button>
                          )}
                          {/* </a> */}
                        </div>
                      </div>
                      {/* </div> */}
                    </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-10 mx-auto bg-white my-3 px-0">
              <div className="row seller-overview">
                <div className="col-md-5">
                  <div>
                    <div className="d-flex">
                      <img
                        className="shopee-avatar__img mt-2"
                        src={
                          this.state.storeData.logoMedium != undefined &&
                          this.state.storeData.logoMedium != null
                            ? this.state.storeData.logoMedium
                            : "https://storage.googleapis.com/assets.shopiads.ca/imagesProd/No-image-found.jpg"
                        }
                      />
                      <div className="section-seller-portrait-info">
                        <h1 className="section-seller-portrait-name">
                          {this.state.storeData.storeName}{" "}
                          {this.state.storeData.code !== "Primary"
                            ? `Branch: ${this.state.storeData.code}`
                            : ""}
                        </h1>
                        <div className="section-seller-portrait-status">
                          <div className="section-seller-active-time">
                            {this.state.storeData.address2}, Unit:
                            {this.state.storeData.address1},{" "}
                            {this.state.storeData.cityId} -{" "}
                            {this.state.storeData.pincode}
                          </div>
                          {/* <div className="section-seller-active-time">
                              Active 7 minutes ago
                            </div> */}
                        </div>
                      </div>
                    </div>
                    <div className="section-seller-overview-buttons">
                      <a className="section-seller-button">
                        <button
                          className={
                            this.state.disabled
                              ? "shopee-button-outline-follow"
                              : "shopee-button-outline"
                          }
                          disabled={this.state.disabled}
                          onClick={() =>
                            this.followStore(this.state.storeData.id)
                          }
                        >
                          {this.state.disabled ? (
                            ""
                          ) : (
                            <span className="section-seller-overview-icon">
                              <svg
                                enable-background="new 0 0 10 10"
                                viewBox="0 0 10 10"
                                x="0"
                                y="0"
                                className="shopee-svg-icon icon-plus-sign"
                              >
                                <polygon points="10 4.5 5.5 4.5 5.5 0 4.5 0 4.5 4.5 0 4.5 0 5.5 4.5 5.5 4.5 10 5.5 10 5.5 5.5 10 5.5"></polygon>
                              </svg>
                            </span>
                          )}
                          {this.state.disabled ? "Following" : "Follow"}
                        </button>
                      </a>
                      <a className="section-seller-button">
                        <button
                          onClick={this.viewStoreClick.bind(
                            this,
                            this.state.storeData.id
                          )}
                          className="shopee-button-outline"
                        >
                          <span className="section-seller-overview-icon">
                            <svg
                              enable-background="new 0 0 15 15"
                              viewBox="0 0 15 15"
                              x="0"
                              y="0"
                              stroke-width="1"
                              class="shopee-svg-icon"
                            >
                              <path d="m13 1.9c-.2-.5-.8-1-1.4-1h-8.4c-.6.1-1.2.5-1.4 1l-1.4 4.3c0 .8.3 1.6.9 2.1v4.8c0 .6.5 1 1.1 1h10.2c.6 0 1.1-.5 1.1-1v-4.6c.6-.4.9-1.2.9-2.3zm-11.4 3.4 1-3c .1-.2.4-.4.6-.4h8.3c.3 0 .5.2.6.4l1 3zm .6 3.5h.4c.7 0 1.4-.3 1.8-.8.4.5.9.8 1.5.8.7 0 1.3-.5 1.5-.8.2.3.8.8 1.5.8.6 0 1.1-.3 1.5-.8.4.5 1.1.8 1.7.8h.4v3.9c0 .1 0 .2-.1.3s-.2.1-.3.1h-9.5c-.1 0-.2 0-.3-.1s-.1-.2-.1-.3zm8.8-1.7h-1v .1s0 .3-.2.6c-.2.1-.5.2-.9.2-.3 0-.6-.1-.8-.3-.2-.3-.2-.6-.2-.6v-.1h-1v .1s0 .3-.2.5c-.2.3-.5.4-.8.4-1 0-1-.8-1-.8h-1c0 .8-.7.8-1.3.8s-1.1-1-1.2-1.7h12.1c0 .2-.1.9-.5 1.4-.2.2-.5.3-.8.3-1.2 0-1.2-.8-1.2-.9z"></path>
                            </svg>
                          </span>
                          View Store
                        </button>
                      </a>
                    </div>
                    <div className="section-seller-overview-buttons">
                      <a className="section-seller-button">
                        <button
                          className="shopee-button-outline"
                          onClick={() => this.openDiscountPage()}
                        >
                          <span className="section-seller-overview-icon">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              class="bi bi-info-square"
                              viewBox="0 0 16 16"
                            >
                              <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                              <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                            </svg>
                          </span>
                          Additional Discounts
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-md-7">
                  <div className="section-info-list">
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-7">
                          <div className="section-seller-details">
                            <div className="section-item-icon-wrapper">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="currentColor"
                                class="bi bi-building shopee-svg-icon"
                                stroke-width="0"
                                viewBox="0 0 16 16"
                              >
                                <path
                                  fill-rule="evenodd"
                                  d="M14.763.075A.5.5 0 0 1 15 .5v15a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5V14h-1v1.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V10a.5.5 0 0 1 .342-.474L6 7.64V4.5a.5.5 0 0 1 .276-.447l8-4a.5.5 0 0 1 .487.022zM6 8.694 1 10.36V15h5V8.694zM7 15h2v-1.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5V15h2V1.309l-7 3.5V15z"
                                />
                                <path d="M2 11h1v1H2v-1zm2 0h1v1H4v-1zm-2 2h1v1H2v-1zm2 0h1v1H4v-1zm4-4h1v1H8V9zm2 0h1v1h-1V9zm-2 2h1v1H8v-1zm2 0h1v1h-1v-1zm2-2h1v1h-1V9zm0 2h1v1h-1v-1zM8 7h1v1H8V7zm2 0h1v1h-1V7zm2 0h1v1h-1V7zM8 5h1v1H8V5zm2 0h1v1h-1V5zm2 0h1v1h-1V5zm0-2h1v1h-1V3z" />
                              </svg>
                            </div>
                            <div className="section-seller-item-text">
                              <div className="section-seller-item-text-name">
                                Incorporated As:&nbsp;
                              </div>
                              <div className="section-seller-item-text-value">
                                {this.state.companyData.regName}
                              </div>
                            </div>
                          </div>
                          <div className="section-seller-details">
                            <div className="section-item-icon-wrapper">
                              <svg
                                enable-background="new 0 0 15 15"
                                viewBox="0 0 15 15"
                                x="0"
                                y="0"
                                stroke-width="0"
                                className="shopee-svg-icon"
                              >
                                <path d="m13 1.9c-.2-.5-.8-1-1.4-1h-8.4c-.6.1-1.2.5-1.4 1l-1.4 4.3c0 .8.3 1.6.9 2.1v4.8c0 .6.5 1 1.1 1h10.2c.6 0 1.1-.5 1.1-1v-4.6c.6-.4.9-1.2.9-2.3zm-11.4 3.4 1-3c .1-.2.4-.4.6-.4h8.3c.3 0 .5.2.6.4l1 3zm .6 3.5h.4c.7 0 1.4-.3 1.8-.8.4.5.9.8 1.5.8.7 0 1.3-.5 1.5-.8.2.3.8.8 1.5.8.6 0 1.1-.3 1.5-.8.4.5 1.1.8 1.7.8h.4v3.9c0 .1 0 .2-.1.3s-.2.1-.3.1h-9.5c-.1 0-.2 0-.3-.1s-.1-.2-.1-.3zm8.8-1.7h-1v .1s0 .3-.2.6c-.2.1-.5.2-.9.2-.3 0-.6-.1-.8-.3-.2-.3-.2-.6-.2-.6v-.1h-1v .1s0 .3-.2.5c-.2.3-.5.4-.8.4-1 0-1-.8-1-.8h-1c0 .8-.7.8-1.3.8s-1.1-1-1.2-1.7h12.1c0 .2-.1.9-.5 1.4-.2.2-.5.3-.8.3-1.2 0-1.2-.8-1.2-.9z"></path>
                              </svg>
                            </div>
                            <div className="section-seller-item-text">
                              <div className="section-seller-item-text-name">
                                Presence:&nbsp;
                              </div>
                              <div className="section-seller-item-text-value">
                                {this.state.storeData.isphysical == "Y"
                                  ? "Online & In-Store"
                                  : "Online"}
                              </div>
                            </div>
                          </div>
                          <div className="section-seller-details">
                            <div className="section-item-icon-wrapper">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="bi bi-truck shopee-svg-icon"
                                viewBox="0 0 16 16"
                                stroke-width="0"
                              >
                                <path d="M0 3.5A1.5 1.5 0 0 1 1.5 2h9A1.5 1.5 0 0 1 12 3.5V5h1.02a1.5 1.5 0 0 1 1.17.563l1.481 1.85a1.5 1.5 0 0 1 .329.938V10.5a1.5 1.5 0 0 1-1.5 1.5H14a2 2 0 1 1-4 0H5a2 2 0 1 1-3.998-.085A1.5 1.5 0 0 1 0 10.5v-7zm1.294 7.456A1.999 1.999 0 0 1 4.732 11h5.536a2.01 2.01 0 0 1 .732-.732V3.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5v7a.5.5 0 0 0 .294.456zM12 10a2 2 0 0 1 1.732 1h.768a.5.5 0 0 0 .5-.5V8.35a.5.5 0 0 0-.11-.312l-1.48-1.85A.5.5 0 0 0 13.02 6H12v4zm-9 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm9 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
                              </svg>
                            </div>
                            <div className="section-seller-item-text">
                              <div className="section-seller-item-text-name">
                                Delivery:&nbsp;
                              </div>

                              <div className="section-seller-item-text-value">
                                {this.state.storeData.deliveryNotes !=
                                  undefined &&
                                this.state.storeData.deliveryNotes != ""
                                  ? "Free Shipping on orders over $" +
                                    this.state.storeData.deliveryNotes
                                  : this.state.storeData.deliveryType != "" &&
                                    this.state.storeData.secondDeliveryType !=
                                      "" &&
                                    this.state.storeData.secondDeliveryType !=
                                      undefined
                                  ? this.state.storeData.deliveryType +
                                    "/" +
                                    this.state.storeData.secondDeliveryType
                                  : this.state.storeData.deliveryType != ""
                                  ? this.state.storeData.deliveryType
                                  : this.state.storeData.secondDeliveryType !=
                                      "" &&
                                    this.state.storeData.secondDeliveryType !=
                                      undefined
                                  ? this.state.storeData.secondDeliveryType
                                  : ""}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-5">
                          <div className="section-seller-details">
                            <div className="section-item-icon-wrapper">
                              <svg
                                enable-background="new 0 0 15 15"
                                viewBox="0 0 15 15"
                                x="0"
                                y="0"
                                className="shopee-svg-icon"
                              >
                                <g>
                                  <circle
                                    cx="5.5"
                                    cy="5"
                                    fill="none"
                                    r="4"
                                    strokeMiterlimit="10"
                                  ></circle>
                                  <path
                                    d="m8.4 7.5c.7 0 1.1.7 1.1 1.6v4.9h-8v-4.9c0-.9.4-1.6 1.1-1.6"
                                    fill="none"
                                    stroke-linejoin="round"
                                    strokeMiterlimit="10"
                                  ></path>
                                  <path
                                    d="m12.6 6.9c.7 0 .9.6.9 1.2v5.7h-2"
                                    fill="none"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    strokeMiterlimit="10"
                                  ></path>
                                  <path
                                    d="m9.5 1.2c1.9 0 3.5 1.6 3.5 3.5 0 1.4-.9 2.7-2.1 3.2"
                                    fill="none"
                                    stroke-linecap="round"
                                    strokeMiterlimit="10"
                                  ></path>
                                </g>
                              </svg>
                            </div>
                            <div className="section-seller-item-text">
                              <div className="section-seller-item-text-name">
                                Followers:&nbsp;
                              </div>
                              <div className="section-seller-item-text-value">
                                {this.state.followers}
                              </div>
                            </div>
                          </div>
                          <div className="section-seller-details">
                            <div className="section-item-icon-wrapper">
                              <svg
                                enable-background="new 0 0 15 15"
                                viewBox="0 0 15 15"
                                x="0"
                                y="0"
                                className="shopee-svg-icon icon-rating"
                              >
                                <polygon
                                  fill="none"
                                  points="7.5 .8 9.7 5.4 14.5 5.9 10.7 9.1 11.8 14.2 7.5 11.6 3.2 14.2 4.3 9.1 .5 5.9 5.3 5.4"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  strokeMiterlimit="10"
                                ></polygon>
                              </svg>
                            </div>
                            <div className="section-seller-item-text">
                              <div className="section-seller-item-text-name">
                                rating:&nbsp;
                              </div>
                              <div className="section-seller-item-text-value">
                                {this.state.storeData.avgRating}
                              </div>
                            </div>
                          </div>
                          <div className="section-seller-details">
                            <div className="section-item-icon-wrapper">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="bi bi-stopwatch shopee-svg-icon"
                                viewBox="0 0 16 16"
                                stroke-width="0"
                              >
                                <path d="M8.5 5.6a.5.5 0 1 0-1 0v2.9h-3a.5.5 0 0 0 0 1H8a.5.5 0 0 0 .5-.5V5.6z" />
                                <path d="M6.5 1A.5.5 0 0 1 7 .5h2a.5.5 0 0 1 0 1v.57c1.36.196 2.594.78 3.584 1.64a.715.715 0 0 1 .012-.013l.354-.354-.354-.353a.5.5 0 0 1 .707-.708l1.414 1.415a.5.5 0 1 1-.707.707l-.353-.354-.354.354a.512.512 0 0 1-.013.012A7 7 0 1 1 7 2.071V1.5a.5.5 0 0 1-.5-.5zM8 3a6 6 0 1 0 .001 12A6 6 0 0 0 8 3z" />
                              </svg>
                            </div>
                            <div className="section-seller-item-text">
                              <div className="section-seller-item-text-name">
                                Timing:&nbsp;
                              </div>
                              <div className="section-seller-item-text-value">
                                <Moment tz="CST" format="hh:mm A">
                                  {this.state.storeData.fromHrs != undefined &&
                                  this.state.storeData.fromHrs != null &&
                                  this.state.storeData.fromHrs.length > 0
                                    ? "1970-01-01T" +
                                      (
                                        "0" +
                                        this.state.storeData.fromHrs[0].split(
                                          ":"
                                        )[0]
                                      ).slice(-2) +
                                      ":" +
                                      (
                                        "0" +
                                        this.state.storeData.fromHrs[0].split(
                                          ":"
                                        )[1]
                                      ).slice(-2) +
                                      ":" +
                                      (
                                        "0" +
                                        this.state.storeData.fromHrs[0].split(
                                          ":"
                                        )[2]
                                      ).slice(-2)
                                    : ""}
                                </Moment>{" "}
                                -{" "}
                                <Moment tz="CST" format="hh:mm A">
                                  {this.state.storeData.toHrs != undefined &&
                                  this.state.storeData.toHrs != null &&
                                  this.state.storeData.toHrs.length > 0
                                    ? "1970-01-01T" +
                                      (
                                        "0" +
                                        this.state.storeData.toHrs[0].split(
                                          ":"
                                        )[0]
                                      ).slice(-2) +
                                      ":" +
                                      (
                                        "0" +
                                        this.state.storeData.toHrs[0].split(
                                          ":"
                                        )[1]
                                      ).slice(-2) +
                                      ":" +
                                      (
                                        "0" +
                                        this.state.storeData.toHrs[0].split(
                                          ":"
                                        )[2]
                                      ).slice(-2)
                                    : ""}
                                </Moment>
                                <Tooltip
                                  placement="bottom"
                                  color={"white"}
                                  title={
                                    <React.Fragment>
                                      <li style={{ color: "gray" }}>
                                        ● {this.state.storeData.timeNote}
                                      </li>
                                      <li style={{ color: "Red" }}>
                                        ●{" "}
                                        {`Closed : ${this.state.storeData.weeklyOff}`}
                                      </li>
                                    </React.Fragment>
                                  }
                                >
                                  <i
                                    className="fa fa-info-circle ml-2"
                                    style={style.tooltipIcon}
                                  ></i>
                                </Tooltip>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row ml-5">
            <div className="col-md-11 mx-auto my-3">
              <div className="row">
                <div className="col-md-10 pl-0">
                  <div className="product-detail bg-white mb-3">
                    <div class="section-title">Product Description</div>
                    <div
                      className="product-description my-4 px-2"
                      style={{ marginLeft: "14px" }}
                    >
                      {this.state.productData.description &&
                      new RegExp("<.*?>").test(
                        this.state.productData.description
                      ) ? (
                        <div>
                          {renderHTML(this.state.productData.description)}
                        </div>
                      ) : (
                        <p>{this.state.productData.description}</p>
                      )}
                    </div>
                  </div>
                  <div className="mb-3 bg-white">
                    <div class="title-m ml-2">Buyer Reviews</div>
                    <div className="bg-light row mx-0 p-4 border">
                      <div className="col-auto">
                        <div className="product-raitng-score">
                          <div>
                            <span className="rating-score">
                              {this.state.productData.avgRating != undefined &&
                              Number(this.state.productData.avgRating) > 0
                                ? this.state.productData.avgRating
                                : 0}
                            </span>{" "}
                            <span className="rating-score">out of 5</span>
                          </div>
                          <div class="rating-star mt-1">
                            <ReactStars
                              key={`stars_${Number(
                                this.state.productData.avgRating
                              )}`}
                              count={5}
                              size={24}
                              isHalf={true}
                              edit={false}
                              value={Number(this.state.productData.avgRating)}
                              activeColor="#ffd700"
                            />
                          </div>
                        </div>
                      </div>

                      {/* rating scrore end here */}
                      <div className="col-md-10">
                        <Button
                          onClick={this.filterClick.bind(this, "all")}
                          variant={
                            this.state.activeFilter == "all"
                              ? "contained"
                              : "outlined"
                          }
                          color="primary"
                          className="mr-2 mb-2"
                        >
                          all
                        </Button>
                        <Button
                          onClick={this.filterClick.bind(this, "5")}
                          variant={
                            this.state.activeFilter == "5"
                              ? "contained"
                              : "outlined"
                          }
                          color="primary"
                          className="mr-2 mb-2"
                        >
                          5 star ({this.state.ratingFilterCount5})
                        </Button>
                        <Button
                          onClick={this.filterClick.bind(this, "4")}
                          variant={
                            this.state.activeFilter == "4"
                              ? "contained"
                              : "outlined"
                          }
                          color="primary"
                          className="mr-2 mb-2"
                        >
                          4 star ({this.state.ratingFilterCount4})
                        </Button>
                        <Button
                          onClick={this.filterClick.bind(this, "3")}
                          variant={
                            this.state.activeFilter == "3"
                              ? "contained"
                              : "outlined"
                          }
                          color="primary"
                          className="mr-2 mb-2"
                        >
                          3 star ({this.state.ratingFilterCount3})
                        </Button>
                        <Button
                          onClick={this.filterClick.bind(this, "2")}
                          variant={
                            this.state.activeFilter == "2"
                              ? "contained"
                              : "outlined"
                          }
                          color="primary"
                          className="mr-2 mb-2"
                        >
                          2 star ({this.state.ratingFilterCount2})
                        </Button>
                        <Button
                          onClick={this.filterClick.bind(this, "1")}
                          variant={
                            this.state.activeFilter == "1"
                              ? "contained"
                              : "outlined"
                          }
                          color="primary"
                          className="mr-2 mb-2"
                        >
                          1 star ({this.state.ratingFilterCount1})
                        </Button>
                        {/*<Button
                            onClick={this.filterClick.bind(this, "c")}
                            variant={
                              this.state.activeFilter == "c"
                                ? "contained"
                                : "outlined"
                            }
                            color="primary"
                            className="mr-2 mb-2"
                          >
                            with comments ({this.state.ratingFilterCountC})
                          </Button>
                          <Button
                            onClick={this.filterClick.bind(this, "m")}
                            variant={
                              this.state.activeFilter == "m"
                                ? "contained"
                                : "outlined"
                            }
                            color="primary"
                            className="mr-2 mb-2"
                          >
                            With Media ({this.state.ratingFilterCountM})
                          </Button>*/}
                      </div>
                      <div className="col-md-12 mt-3">
                        <div className="row align-items-center">
                          <div className="col-auto pr-0">
                            {/* <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" fill="#1d6042" class="bi bi-patch-check-fill" viewBox="0 0 16 16">
                          <path d="M10.067.87a2.89 2.89 0 0 0-4.134 0l-.622.638-.89-.011a2.89 2.89 0 0 0-2.924 2.924l.01.89-.636.622a2.89 2.89 0 0 0 0 4.134l.637.622-.011.89a2.89 2.89 0 0 0 2.924 2.924l.89-.01.622.636a2.89 2.89 0 0 0 4.134 0l.622-.637.89.011a2.89 2.89 0 0 0 2.924-2.924l-.01-.89.636-.622a2.89 2.89 0 0 0 0-4.134l-.637-.622.011-.89a2.89 2.89 0 0 0-2.924-2.924l-.89.01-.622-.636zm.287 5.984-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708.708z" />
                </svg>*/}
                            <img
                              src="https://storage.googleapis.com/assets.shopiads.ca/imagesProd/Verified_Image.png"
                              width="60"
                            ></img>
                          </div>
                          <div className="col-auto">
                            {/* <b>Verified Reviews</b> */}
                            <p className="mb-0">
                              Ratings and reviews are provided by verified
                              buyers who had purchased through ShopiAds!
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    {this.state.productRating.slice(0, 5).map((item, index) => (
                      <div className="rating-list mt-4">
                        <ul class="list-unstyled">
                          <li class="media">
                            <div class="">
                              <img
                                src={Profile}
                                style={{ width: "30px", borderRadius: "50%" }}
                                //className={classes.userIcon}
                                //onClick={handleMenu}
                                alt="PROFILE"
                              />
                            </div>
                            <div class="media-body">
                              <small class="mt-0 mb-1 ml-2">
                                {item.userName}
                              </small>
                              <div class="rating-star mt-1">
                                <ReactStars
                                  key={`stars_${Number(item?.rating)}`}
                                  count={5}
                                  size={24}
                                  isHalf={true}
                                  edit={false}
                                  value={item?.rating}
                                  activeColor="#ffd700"
                                />
                              </div>
                              <p className="text-muted mb-1 small mt-3">
                                Reviewed on{" "}
                                <Moment
                                  tz="CST"
                                  format="DD MMM YYYY"
                                  filter={this.toUpperCaseFilter}
                                >
                                  {item.createdOn}
                                </Moment>
                              </p>
                              <b className="text-purchased mb-1">
                                Verified Purchase
                              </b>
                              <p className="medium">{item.comment}</p>

                              {item.ratingMedia != null &&
                              item.ratingMedia != undefined &&
                              item.ratingMedia.length > 0 &&
                              this.state.mediaItem != undefined &&
                              this.state.mediaItem != null &&
                              this.state.mediaItem.length > 0 ? (
                                <div className="gallery">
                                  {this.imageGallery(
                                    this.state.mediaItem[index],
                                    index
                                  )}
                                  {
                                    <Dialog
                                      // onClick={this.handleClose}
                                      aria-labelledby="customized-dialog-title"
                                      open={this.state.open}
                                      className="dialog-container"
                                    >
                                      <MuiDialogTitle>
                                        <IconButton
                                          aria-label="close"
                                          className="close-icon"
                                          onClick={this.onImageCilck.bind(this)}
                                        >
                                          <CloseIcon />
                                        </IconButton>
                                      </MuiDialogTitle>
                                      <Swiper
                                        spaceBetween={20}
                                        slidesPerView={1}
                                        navigation
                                        loop={true}
                                        onSlideChange={(swiper) => {
                                          console.log("swiper");
                                          // handlePause(swiper.previousIndex);
                                        }}
                                        onSwiper={(swiper) =>
                                          console.log(swiper)
                                        }
                                        className="customized-images"
                                        style={{
                                          position: "none !imporatnt",
                                        }}
                                      >
                                        {this.state.mediaItem[
                                          this.state.key
                                        ] !== undefined
                                          ? this.state.mediaItem[
                                              this.state.key
                                            ].map((item, index) => (
                                              <SwiperSlide
                                                style={{
                                                  width: "200px !important",
                                                }}
                                              >
                                                <div>
                                                  {/* {item.embedUrl ? (
                                                      <ReactPlayer
                                                        controls
                                                        playIcon={true}
                                                        width="600px"
                                                        url={item.embedUrl}
                                                      />
                                                    ) : ( */}
                                                  <img
                                                    alt="images"
                                                    style={{
                                                      // paddingLeft: "2%",
                                                      width: "600px",
                                                      height: "500px",
                                                    }}
                                                    src={item.original}
                                                  />
                                                  {/* )} */}
                                                </div>
                                              </SwiperSlide>
                                            ))
                                          : ""}
                                      </Swiper>
                                    </Dialog>
                                  }
                                </div>
                              ) : (
                                ""
                              )}
                              {/*  <div className="d-flex mt-3 justify-content-between">
                          <a href="#" className="text-muted "><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-hand-thumbs-up-fill" viewBox="0 0 16 16">
                            <path d="M6.956 1.745C7.021.81 7.908.087 8.864.325l.261.066c.463.116.874.456 1.012.965.22.816.533 2.511.062 4.51a9.84 9.84 0 0 1 .443-.051c.713-.065 1.669-.072 2.516.21.518.173.994.681 1.2 1.273.184.532.16 1.162-.234 1.733.058.119.103.242.138.363.077.27.113.567.113.856 0 .289-.036.586-.113.856-.039.135-.09.273-.16.404.169.387.107.819-.003 1.148a3.163 3.163 0 0 1-.488.901c.054.152.076.312.076.465 0 .305-.089.625-.253.912C13.1 15.522 12.437 16 11.5 16H8c-.605 0-1.07-.081-1.466-.218a4.82 4.82 0 0 1-.97-.484l-.048-.03c-.504-.307-.999-.609-2.068-.722C2.682 14.464 2 13.846 2 13V9c0-.85.685-1.432 1.357-1.615.849-.232 1.574-.787 2.132-1.41.56-.627.914-1.28 1.039-1.639.199-.575.356-1.539.428-2.59z" />
                          </svg> Helpful?</a>
                          <DropdownButton id="dropdown-item-button" title="Report">
                            <Dropdown.Item as="button">Report Abuse</Dropdown.Item>
                          </DropdownButton>
                </div>*/}
                            </div>
                          </li>
                        </ul>
                      </div>
                    ))}
                    {this.state.productRating.length > 5 && (
                      <a href={`/rating/${this.state.productId}`}>
                        <p>View more reviews</p>
                      </a>
                    )}
                  </div>

                  {/* from shop product start here */}

                  <div class="pl-0 mb-3 mt-5">
                    <div class="d-flex align-items-center justify-content-between shopee-header-section__header pb-2">
                      <div class="shopee-header-section__header__title">
                        <div>SIMILAR PRODUCTS</div>
                      </div>
                      <button
                        onClick={() => {
                          this.props.history.push(
                            `/search/${Buffer.from(
                              this.state.productData.heading
                            ).toString("hex")}/all`
                          );
                        }}
                        className="shopee-button-no-outline"
                        style={{ color: COLORS.primary.main,fontWeight:"bold" }}
                      >
                        View All &nbsp;
                        <svg
                          enable-background="new 0 0 11 11"
                          viewBox="0 0 11 11"
                          x="0"
                          y="0"
                          height="12px"
                          width="12px"
                          className="icon-arrow-right"
                        >
                          <path d="m2.5 11c .1 0 .2 0 .3-.1l6-5c .1-.1.2-.3.2-.4s-.1-.3-.2-.4l-6-5c-.2-.2-.5-.1-.7.1s-.1.5.1.7l5.5 4.6-5.5 4.6c-.2.2-.2.5-.1.7.1.1.3.2.4.2z"></path>
                        </svg>
                      </button>
                    </div>
                    <Swiper
                      spaceBetween={10}
                      slidesPerView={5}
                      slidesPerGroup={5}
                      navigation
                    >
                      {this.state.sameStoreProduct !== undefined &&
                      this.state.sameStoreProduct != null &&
                      this.state.sameStoreProduct.length > 0
                        ? this.state.sameStoreProduct.map((item, index) => (
                            <SwiperSlide>
                              <div>
                                <ProductCard
                                  branchName={item.storeName}
                                  storeId={item.storeId}
                                  productId={item.id}
                                  img={item.images}
                                  branchLogo={item.logo}
                                  heading={item.heading}
                                  avgRating={item.avgRating}
                                  discount={item.discountPerc}
                                  actualPrice={item.actualPrice}
                                  offerPrice={item.offerPrice}
                                  totalLikes={item.totalLikes}
                                  key={item.id}
                                />
                              </div>
                            </SwiperSlide>
                          ))
                        : ""}
                    </Swiper>
                  </div>
                </div>
                <div className="col-md-2 pr-0">
                  {/* side product start here */}
                  {this.state.sameCatProduct != undefined &&
                  this.state.sameCatProduct != null &&
                  this.state.sameCatProduct.length > 0
                    ? this.state.sameCatProduct.map((item, index) =>
                        index <= 3 && item.id != this.state.productData.id ? (
                          <div className="mb-3" style={{ width: "250px" }}>
                            <ProductCard
                              branchName={item.storeName}
                              storeId={item.storeId}
                              productId={item.id}
                              img={item.images}
                              branchLogo={item.logo}
                              heading={item.heading}
                              avgRating={item.avgRating}
                              discount={item.discountPerc}
                              actualPrice={item.actualPrice}
                              offerPrice={item.offerPrice}
                              totalLikes={item.totalLikes}
                              key={item.id}
                            />
                          </div>
                        ) : (
                          ""
                        )
                      )
                    : ""}
                  {/* side product end here */}

                  {this.state.sameCatProduct != undefined &&
                  this.state.sameCatProduct != null &&
                  this.state.sameCatProduct.length > 4 ? (
                    <div className="text-center">
                      <Button
                        variant="contained"
                        onClick={this.viewAllCatProductClick.bind(
                          this,
                          this.state.sameCatProduct[0].categories[0]
                        )}
                        color="primary"
                        style={{ color: COLORS.text.secondary }}
                      >
                        View All
                      </Button>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-4"></div>

          {this.state.ratingForm && (
            <RatingForm
              handleOpen={this.handleOpenForm}
              handleClose={this.handleCloseForm}
              open={this.state.ratingForm}
              storeId={this.state.productData.storeId}
              productId={this.state.productData.id}
              offerId={""}
              type={"Product"}
            />
          )}
          {this.state.loginForm && (
            <LoginForm
              handleOpen={this.handleLoginOpenForm}
              handleClose={this.handleLoginCloseForm}
              open={this.state.loginForm}
            />
          )}
          <ShopifyStoreDiscount
            handleOpen={this.handleOpenDiscountForm}
            handleClose={this.handleCloseDiscountForm}
            storeData={this.state.storeData}
            open={this.state.discountForm}
          />
          <ToastContainer />
        </div>
      </>
    );
  }
}



const mapStateToProps = (state) => ({
  allSnips: state.snips,
  allLikes: state.snips,
  loading: state.snips,
  selectedRefMarketPlace:state.location.marketPlace,
});

const mapDispatchToProps = (dispatch) => {
  return {
    pushSnips: (snipBody) => pushSnipsData(snipBody),
    getUserSnips: (email, like) => getUserSnips(email, like),
  };
};
export default connect(mapStateToProps, mapDispatchToProps())(ProductDetails);