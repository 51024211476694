import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Grid, makeStyles, Tab, Tabs } from "@material-ui/core";
import { useHistory, useRouteMatch } from "react-router-dom";
import { getUserSnips } from "../../../Redux/Snips/SnipsAction";
import { useAuth } from "../../../Utils/AuthContext";
import API from "../../../Utils/API";
import {
  getAllStores,
  getStoresAllProduct,
  getStoresByCategory,
  getStoresProductByCategory,
} from "../../../Redux/Stores/StoresActions";
import RatingForm from "../../DialogBox/RatingForm";
import { OfferCard } from "./OfferCard";
import ReactPaginate from "react-paginate";
import { leftPaginationArrow, rightPaginationArrow } from "./SectionProducts";
import { getDomainCountryCode } from "../../../service/location";

const style = {
  backButton: {
    fontSize: "25px",
    marginBottom: "10px",
    marginLeft: "12px",
    color: "#1d6042",
    cursor: "pointer",
  },
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  tabs: {
    "& button": {
      "& .MuiTab-wrapper": {
        flexDirection: "row !important",
        justifyContent: "left !important",
      },
    },
  },
  selectedItem: {
    "& span": {
      color: "var(--primarycolor)",
    },
  },
  unSelectedItem: {
    color: "black",
  },
}));

function SectionOfferCard() {
  const classes = useStyles();
  const { userInfo } = useAuth();
  const { path, params } = useRouteMatch();
  const currPath = path.split(":")[0];
  const dispatch = useDispatch();
  const history = useHistory();
  let allCategories = [];
  const {
    userId,
    cityId,
    stateId,
    marketPlace: selectedRefMarketPlace,
  } = useSelector((state) => state.location);
  const {
    allStores,
  } = useSelector((state) => state.stores);
  const [recordPerPage, setRecordPerPage] = useState(32)
  const [value, setValue] = useState(0);
  const [mainCategoryList, setMainCategoryList] = useState([]);
  const [allCategoriesArray, setAllCategoriesArray] = useState([]);
  const [ratingForm, setRatingForm] = useState(false);
  const [storeId, setStoreId] = useState();
  const [offerId, setOfferId] = useState();
  const [offersData, setOffersData] = useState([]);
  const [category, setCategory] = useState("Trending")
  const [section, setSection] = useState({})
  const [items, setItems] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  
  // const handleSelect = (selectedIndex, e) => {
  //   setIndex(selectedIndex);
  // };

  useEffect(() => {
    console.log("dsafghakjsjbd,cksajlnkz")
    API.getSectionById(params.sectionName).then(data => { 
      console.log({
        sectionName:data
      })
      setSection(data);
    })
    // setValue(allCategoriesArray.indexOf(params.tabName));
    dispatch(getUserSnips(userInfo?.email, "like"));
    API.getMainCategories(
      userInfo != null && userInfo != undefined ? userInfo.email : "gust"
    )
      .then((result) => {
        if (result !== undefined && result.length > 0) {
          setMainCategoryList(result);
          allCategories = ["Trending"].concat(
            result.map((category) => category.name)
          );
          let categoryId = "Trending";
          result.forEach(cat => { 
            if (cat.name === params.tabName) { 
              categoryId = cat.id;
            }
          })
          setCategory(categoryId);
          setAllCategoriesArray(allCategories);
          setValue(allCategories.indexOf(params.tabName));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [params]);

  useEffect(() => {
    console.log({category,"params.tabName":params.tabName})
    API.getOffersByOfferSection(
      category,
      cityId,
      section.id,
      stateId,
      "Offer",
      selectedRefMarketPlace,
      1,
      32
    ).then((response) => {
      let {
        content,
        pageable: { pageNumber },
        totalPages: numberOfPages,
      } = response;
      setItems(content);
      setCurrentPage(pageNumber + 1);
      setTotalPages(numberOfPages);
    });
  }, [category, mainCategoryList,section]);
  
  const handleOpenForm = () => setRatingForm(true);

  const handleCloseForm = () => setRatingForm(false);

  const handleChange = (event, newValue) => {
    const tabName = event.target.innerText;
    setValue(newValue);

    if (tabName !== "NEW") {
      dispatch(
        getStoresByCategory(
          userInfo?.email || userId,
          tabName === "TRENDING" ? "trending" : mainCategoryList[newValue - 1].id,
          cityId,
          stateId,
          "offer",
          selectedRefMarketPlace
        )
      );
    } else {
      dispatch(
        getAllStores(userInfo?.email || userId, cityId, stateId, "offer",selectedRefMarketPlace)
      );
      const searchQuery = API.generateQueryString({
        user: API.encodedUserId(userInfo?.email || userId),
        cityId,
        stateId,
        viewType: "offer",
        marketPlace: selectedRefMarketPlace,
        pageNo: 0,
        recordPerPage,
        orderBy: "createdOn",
        asc: getDomainCountryCode() === "CA" ? true : false
      });
      dispatch(getStoresAllProduct(searchQuery));
    }
  };

  const handleMenuChange = (newValue, index) => {
    //const tabName = event.target.innerText;
    history.push(`${currPath}${newValue}`);
    setValue(newValue);
    // setSelectedIndex(index);
    var CatId = "";
    mainCategoryList.map((item, index) => {
      if (item.name == newValue) CatId = item.id;
    });

    dispatch(
      getStoresByCategory(
        userInfo?.email || userId,
        CatId,
        cityId,
        stateId,
        "offer"
      )
    );
    dispatch(
      getStoresProductByCategory({
        user:API.encodedUserId(userInfo != null && userInfo !== undefined ? userInfo.email : "gust"),
        categoryId: CatId,
        cityId,
        stateId,
        viewType: "offer",
        marketPlace: selectedRefMarketPlace,
        pageNo: 0,
        recordPerPage,
        orderBy: "createdOn",
        asc:false
      })
    );
  };


  function checkIfImageExists(url) {
    const img = new Image();
    img.src = url;
    
    if (img.complete) {
      return true;
    } else {
      img.onload = () => {
        return true;
      };
      
      img.onerror = () => {
        return false;
      };
    }
  }

  return (
    <>
      <Grid container className="mt-3">
        <Grid item xs={2}>
          <div className="menu-tab">
            <i
             style={{...style.backButton, position:'relative'}}
              className="fa fa-arrow-circle-left"
              aria-hidden="true"
              title="Back to Login"
              onClick={() =>
                history.push(`/offers/${window.location.href.split("/")[5]}`)
              }
            ></i>
            <Tabs
              value={value}
              indicatorColor="primary"
              textColor="primary"
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="off"
              className={classes.tabs}
              orientation="vertical"
            >
              {allCategoriesArray.map((item, index) =>
               
                  <Tab
                    {...a11yProps(index)}
                    label={item}
                    key={item}
                    onClick={() => {
                        history.push(`/${params.sectionName}/allOffers/${item}`)
                        setCurrentPage(1);
                      }
                    }
                    style={{ minWidth: "120px",textAlign:'left'}}
                    wrapped
                  />
                
              )}
            </Tabs>
          </div>
        </Grid>
        <Grid item xs={10}>
        <h4 className="ml-5">
            <strong>
              {section.name} Products
            </strong>
          </h4>
          <Grid container className="ml-5">
            {items.length > 0
              ? items.map((item, index) => (
               // checkIfImageExists(item.fullImage)?
                  <div style={{ minWidth: "340px" }}>
                    <OfferCard
                      branchName={item.storeName}
                      storeId={item.storeId}
                      offerId={item.id}
                      img={item.fullImage}
                      branchLogo={item.logo}
                      logoSmall={item.logoSmall}
                      heading={item.heading}
                      avgRating={item.avgRating}
                      totalLikes={item.totalLikes}
                      key={item.id}
                    />
                  </div>//:""
                ))
              : <h4>Coming up soon in future in {params.tabName}</h4>}
          </Grid>
        </Grid>
      </Grid>

      {ratingForm && (
        <RatingForm
          handleOpen={handleOpenForm}
          handleClose={handleCloseForm}
          open={ratingForm}
          storeId={storeId}
          productId={""}
          offerId={offerId}
          type={"Offer"}
        />
      )}
      {totalPages > 1 && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <ReactPaginate
            forcePage={currentPage-1}
            pageCount={totalPages}
            pageRangeDisplayed={3} // Number of pages to display in the pagination
            marginPagesDisplayed={1} // Number of pages to display on the edges
            previousLabel={leftPaginationArrow}
            nextLabel={rightPaginationArrow}
            breakLabel={"..."}
            containerClassName={"pagination"}
            activeClassName={"active"}
            disabledClassName={"disabled"}
            onPageChange={(e) => {
              const selectedPage = e.selected;
              console.log("currentPage::",selectedPage)
              API.getOffersByOfferSection(
                category,
                cityId,
                section.id, stateId, "Offer",
                selectedRefMarketPlace,selectedPage + 1, 32
              ).then(response => { 
                let {
                  content,
                  pageable: { pageNumber },
                  totalPages: numberOfPages,
                } = response;
                setItems(content);
                setCurrentPage(pageNumber + 1);
                setTotalPages(numberOfPages);
              })
              window.scrollTo(0, 0);
            }}
          />
        </div>
      )}
    </>
  );
}

export default SectionOfferCard;
