import { createMuiTheme } from "@material-ui/core";
export const COLORS = {
  primary: {
    light: "#4a8c68",
    main: "#1d6042",
    dark: "#00371d",
    contrastText: "#ffffff",
  },
  secondary: {
    light: "#ff7d47",
    main: "#ff5722",
    dark: "#c41c00",
    contrastText: "#ffffff",
  },
  error: {
    light: "#e57373",
    main: "#f44336",
    dark: "#d32f2f",
    contrastText: "#ffffff",
  },
  warning: {
    light: "#ffb74d",
    main: "#ff9800",
    dark: "#f57c00",
    contrastText: "#000000",
  },
  info: {
    light: "#64b5f6",
    main: "#2196f3",
    dark: "#1976d2",
    contrastText: "#ffffff",
  },
  success: {
    light: "#81c784",
    main: "#4caf50",
    dark: "#388e3c",
    contrastText: "#000000",
  },
  background: {
    // default: "#f5f5f5",
    // paper: "#ffffff",
  },
  text: {
    primary: "#000000",
    secondary: "#ffffff",
    disabled: "#9e9e9e",
    hint: "#9e9e9e",
  },
};
export const muiTheme = createMuiTheme({
  palette: { ...COLORS },
});

export default muiTheme;
