import React, { Events, useState } from "react";
import "../Container.css";
import { useAuth } from "../../../Utils/AuthContext";
import { useSelector, useDispatch } from "react-redux";
import { getAllStores } from "../../../Redux/Stores/StoresActions";

import Card from "react-bootstrap/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import CardHeader from "@material-ui/core/CardHeader";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import API from "../../../Utils/API";
import Profile from "../../../Assets/profile.jpeg";
import {
  useHistory,
  useRouteMatch,
  Link,
  matchPath,
  useParams,
} from "react-router-dom";
// import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
// import { Carousel } from "react-responsive-carousel";
import Carousel from "react-bootstrap/Carousel";
import { pushSnipsData, getUserSnips } from "../../../Redux/Snips/SnipsAction";
import LoginForm from "../../DialogBox/LoginForm";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { uuid } from "../../../Utils/uuid";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import ImageGallery from "react-image-gallery";
import Moment from "react-moment";
import "moment-timezone";
import { connect } from "react-redux";
import AuthContext from "../../../Utils/AuthContext";
import SliderImage from "react-zoom-slider";
import RatingForm from "../../DialogBox/RatingForm";
import ProductCard from "./ProductCard";
import ReactPlayer from "react-player";
import { Dialog, DialogTitle } from "@material-ui/core";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination } from "swiper";
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Chip from '@material-ui/core/Chip';
import CounterInput from 'react-bootstrap-counter';
import { QuantityPicker } from "react-qty-picker";
import { makeStyles } from '@material-ui/core/styles';
import ReactStars from "react-rating-stars-component";
import ReactImageZoom from "react-image-zoom";
import ReactImageMagnify from "react-image-magnify";
import {
  FacebookShareButton,
  FacebookMessengerShareButton,
  PinterestShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";
import TwitterIcon from "../../../Assets/twitterx-stroke.png";

import {
  FacebookIcon,
  FacebookMessengerIcon,
} from "react-share";
import Slider from "react-slick";
import { withStyles } from "@material-ui/core/styles";
import { Tooltip } from "antd";
import "antd/dist/antd.css";
import PropTypes from 'prop-types';
import renderHTML from 'react-render-html';
import ShopifyStoreDiscount from "../../DialogBox/ShopifyStoreDiscount";
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop'
import { COLORS } from "../../../Utils/context/ThemeContext";
class Rating extends React.Component {
  static contextType = AuthContext;
  constructor(props) {
    super();
    this.state = {
      showFullscreenButton: true,
      showGalleryFullscreenButton: true,
      showPlayButton: true,
      showGalleryPlayButton: true,

      showVideo: {},
      useWindowKeyDown: true,

      loading: false,
      productData: [],
      productRating: [],
      productRatingData: [],
      sameCatProduct: [],
      sameStoreProduct: [],
      storeData: [],
      companyData: [],
      ratingFilterCount1: 0,
      ratingFilterCount2: 0,
      ratingFilterCount3: 0,
      ratingFilterCount4: 0,
      ratingFilterCount5: 0,
      ratingFilterCountC: 0,
      ratingFilterCountM: 0,
      activeFilter: "all",
      mediaItem: [],
      productImage: [],
      displayImage: {},
      ratingForm: false,
      loginForm: false,
      discountForm: false,
      userInfo: {},
      open: false,
      key: 0,
      liked: false,
      likesLoading: false,
      likes: 0,
      snipped: false,
      previousPath: "",
      rated: false,
      followers: 0,
      variantList: [],
      selectedVariantOption1: "",
      selectedVariantOption2: "",
      selectedVariantOption3: "",
      selectedQuantity: 1,
      disabled: false,
      productId: props.match.params.productId,
      numberOfRating: 10,
    };
    // const  params  = useParams();
    //const dispatch = useDispatch();

    // const { userInfo } = useAuth();

    // const mediaItem=[];
    this.filterClick = this.filterClick.bind(this);
    this.handleSnip = this.handleSnip.bind(this);
    this.slideClick = this.slideClick.bind(this);
    this.handleOpenForm = this.handleOpenForm.bind(this);
    this.handleCloseForm = this.handleCloseForm.bind(this);
    this.handleVariantShopifyChanage = this.handleVariantShopifyChanage.bind(this);

    //const handleOpenForm = () => this.setState({ loginForm:true});

    //const handleCloseForm = () => this.setState({ loginForm:false});
    //const userInfo  = useAuth();
  }
  handleLoginOpenForm = () => this.setState({ loginForm: true });
  handleLoginCloseForm = () => this.setState({ loginForm: false });

  handleOpenForm = () => this.setState({ ratingForm: true });
  handleCloseForm = () => this.setState({ ratingForm: false });

  handleOpenDiscountForm = () => this.setState({ discountForm: true });
  handleCloseDiscountForm = () => this.setState({ discountForm: false });

  fetchDetails = () => {
    API.getStoreById(this.props.match.params.storeId)
      .then((result) => {
        if (result != undefined) {
          // setStoreData(result)
          this.setState({ storeData: result });
          API.getStoreFollowList(this.props.match.params.storeId)
            .then((response) => {
              if (response != undefined) {
                this.setState({ followers: response.length });
              }
            })
            .catch((error) => {
              console.log(error);
            });
          API.getCompanyId(result.companyId)
            .then((result1) => {
              if (result1 != undefined) {
                this.setState({ companyData: result1 });
              }
            })
            .catch((error) => {
              console.log(error);
              console.log(error);
            });
        }
      })
      .catch((error) => {
        console.log(error);
        console.log(error);
      });
    this.fetchProductDetails();
  }
  fetchProductDetails = () => {
    this.setState({ loading: true });
    const user = this.context;
    API.productById("gust", this.state.productId)
      .then((result) => {
        if (result != undefined) {
          this.setState({ productData: result });
          this.setState({ selectedVariantOption1: result.variantOption1 });
          this.setState({ selectedVariantOption2: result.variantOption2 });
          this.setState({ selectedVariantOption3: result.variantOption3 });
          this.props.allLikes.allLikes.filter((e) => e.type === "Product") &&
            this.props.allLikes.allLikes.filter((e) => e.productId === result.id)
              .length > 0
            ? this.setState({ liked: true })
            : this.setState({ liked: false });
          API.getUserSnips(user?.userInfo?.email || "gust", "snip")
            .then((result1) => {
              if (result1 !== undefined) {
                result1.filter((e) => e.product.id === result.id).length > 0
                  ? this.setState({ snipped: true })
                  : this.setState({ snipped: false });
                console.log(result1);
              }
            })
            .catch((err) => {
              console.log(err);
            });
          API.getUserProductRating(user?.userInfo?.email || "gust")
            .then((response) => {
              if (response !== undefined) {
                response.filter((e) => e.id === result.id).length > 0
                  ? this.setState({ rated: true })
                  : this.setState({ rated: false });
              }
            })
            .catch((err) => {
              console.log(err);
            });

          this.setState({ likes: result.totalLikes });
          this.setState({ loading: false });

          API.productByCategoryNew({
            // TODO: provided default user name, can be removed from the backend
            userId: API.encodedUserId("gust"),
            categoryId: result.categories[0],
            marketPlace: this.props.selectedRefMarketPlace,
            pageId: 0,
            recordPerPage: 32
          })
            .then((result1) => {
              const {
                content,
              } = result1;
              if (content.length > 0) {
                //console.log('data=='+result.data);
                this.setState({ sameCatProduct: content });
              } else this.setState({ sameCatProduct: [] });
            })
            .catch((error) => {
              console.log(error);
              console.log(error);
            });
          API.getProductSearch(`text=${btoa(result.heading).replaceAll(/\W/g," ")}&catId=${result.mainCategory}&pageId=0&recordPerPage=${32}&marketPlace=${this.props.selectedRefMarketPlace}`).then(similarProducts => {
            const { content } = similarProducts;
            this.setState({ sameStoreProduct: content.filter(item => item.id !== this.state.productId) });
          })
          if (
            result.images != undefined &&
            result.images != null &&
            result.images.length > 0
          ) {
            const images = [
              {
                image: result.images[0],
                text: "",
              },
              ...(result.images.length > 1
                ? [
                  {
                    image: result.images[1],
                    text: "",
                  },
                ]
                : []),
              ...(result.images.length > 2
                ? [
                  {
                    image: result.images[2],
                    text: "",
                  },
                ]
                : []),
              ...(result.images.length > 3
                ? [
                  {
                    image: result.images[3],
                    text: "",
                  },
                ]
                : []),
              ...(result.images.length > 4
                ? [
                  {
                    image: result.images[4],
                    text: "",
                  },
                ]
                : []),
              ...(result.images.length > 5
                ? [
                  {
                    image: result.images[5],
                    text: "",
                  },
                ]
                : []),
              ...(result.images.length > 6
                ? [
                  {
                    image: result.images[6],
                    text: "",
                  },
                ]
                : []),
              ...(result.images.length > 7
                ? [
                  {
                    image: result.images[7],
                    text: "",
                  },
                ]
                : []),
              ...(result.images.length > 8
                ? [
                  {
                    image: result.images[8],
                    text: "",
                  },
                ]
                : []),
              ...(result.images.length > 9
                ? [
                  {
                    image: result.images[9],
                    text: "",
                  },
                ]
                : []),
              ...(result.images.length > 10
                ? [
                  {
                    image: result.images[10],
                    text: "",
                  },
                ]
                : []),
            ];
            console.log(images, "imageeeeeeee");
            this.setState({ productImage: images });
            this.setState({ displayImage: images[0] });
          }
        } else {
          this.setState({ productData: [] });
          this.setState({ loading: false });
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.log(error);
        // console.log(error);
      });

    API.getRatingByProduct(this.state.productId)
      .then((result) => {
        if (result != undefined) {
          console.log(result, "rrrrrrrrrrrrr");
          // setProductRating(result)
          this.setState({ productRating: result });
          //setProductRatingData(result)
          this.setState({ productRatingData: result });
          //********************************************************* */

          this.setRatingMedia(result);

          /*************************************************************** */
          const obj1 = result.filter((item) => item.rating == "1");
          // setRatingFilterCount1(obj1.length);
          this.setState({ ratingFilterCount1: obj1.length });
          const obj2 = result.filter((item) => item.rating == "2");
          this.setState({ ratingFilterCount2: obj2.length });
          const obj3 = result.filter((item) => item.rating == "3");
          this.setState({ ratingFilterCount3: obj3.length });
          const obj4 = result.filter((item) => item.rating == "4");
          this.setState({ ratingFilterCount4: obj4.length });
          const obj5 = result.filter((item) => item.rating == "5");
          this.setState({ ratingFilterCount5: obj5.length });
          const objC = result.filter(
            (item) =>
              item.comment != "" &&
              item.comment != null &&
              item.comment != undefined
          );
          this.setState({ ratingFilterCountC: objC.length });
          const objM = result.filter(
            (item) =>
              item.ratingMedia != "" &&
              item.ratingMedia != null &&
              item.ratingMedia != undefined &&
              item.ratingMedia.length > 0
          );
          this.setState({ ratingFilterCountM: objM.length });
        }
      })
      .catch((error) => {
        console.log(error);
        console.log(error);
      });
  }
  componentDidMount() {
    // const userContext = useAuth;
    const user = this.context;
    this.setState({ userInfo: user.userInfo });
    this.fetchDetails();
    API.getVariantProducts(this.state.productId)
      .then((result1) => {
        if (result1 != undefined) {
          this.setState({ variantList: result1 });
        }
      })
      .catch((error) => {
        console.log(error);
        console.log(error);
      });
    window.scrollTo(0, 0)
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.productId !== this.state.productId) {
      this.fetchProductDetails();
    }
  }

  handleSnip = (dataObj) => {
    console.log("user info>>>>>>>>>>>>>>>>", this.state.userInfo);
    if (!this.state.userInfo?.email) this.setState({ loginForm: true });
    else {
      console.log("all snip>>>>>>>>>>", this.props.allSnips.allSnips);
      console.log("dataObj snip>>>>>>>>>>", dataObj);
      const obj = this.props.allSnips.allSnips.filter(
        (item) => item?.product != null && item.product.id === dataObj.id
      );
      console.log("all obj>>>>>>>>>>", obj);
      if (obj != null && obj != undefined && obj != "") {
        toast.error("Product already in snip..");
      } else {
        API.getStoreById(dataObj.storeId)
          .then((result) => {
            if (result != undefined) {
              var snapList = [];
              let snipBody = {
                id: uuid(),
                userId: this.state.userInfo?.email,
                product: dataObj,
                activityType: "snip",
                storeId: dataObj.storeId,
                storeName: result.storeName,
                storeCode: result.code,
                productId: dataObj.id,
                type: "Product",
              };
              snapList.push(snipBody);
              API.addSnip(this.state.userInfo?.email, snipBody);
              this.props.pushSnips(snipBody);
              this.setState({ snipped: true });
            }
          })
          .catch((error) => {
            console.log(error);
            console.log(error);
          });
      }
    }
  };

  handleBuy = async (vendorUrl, isShopifyProduct, varientId) => {
    console.log("Handle Verify Changes Buy Now :::::", vendorUrl, isShopifyProduct, varientId);
    if (!this.state.userInfo?.email) this.setState({ loginForm: true });
    else {
      API.productBuyNow(
        this.state.userInfo?.email,
        this.state.productId
      );
      if (isShopifyProduct == "Y") {
        console.log("this.state.storeData.websiteUrl?????????????", this.state.storeData.websiteUrl);
        const shopifyToken = await API.getShopifyShopToken(this.state.storeData.websiteUrl);
        let storeFrontToken = "";
        if (shopifyToken != undefined && shopifyToken != null && shopifyToken != "") {
          storeFrontToken = shopifyToken.storefrontToken
        }
        var catUrl = "https://" + this.state.storeData.websiteUrl + "/cart/" + varientId + ":" + this.state.selectedQuantity + "?access_token=" + storeFrontToken + "&checkout[email]=" + this.state.userInfo?.email + "&attributes[utm_source]=shopiads";
        // var catUrl="http://localhost:3000/redirectToCart/"+this.props.match.params.productId+"/"+this.state.userInfo?.email;
        console.log("ValidateUrl :::::", catUrl)
        //window.location.replace(catUrl);
        window.open(catUrl, '_blank');
      } else {
        //window.location.replace(vendorUrl+"?utm_source=shopiads");
        window.open(vendorUrl + "?utm_source=shopiads", '_blank');
      }
      // window.location.replace(vendorUrl+"?utm_source=shopiads");
      //window.open(vendorUrl+"?utm_source=shopiads", '_blank');
    }
  };

  setRatingMedia(data) {
    const mediaItem = [];
    console.log("this.state.productRating>>>>>>>>>>>>>>>>>>>>>", data);
    data.map((item, index) => {
      if (
        item.ratingMedia != null &&
        item.ratingMedia != undefined &&
        item.ratingMedia.length > 0
      ) {
        const images = [
          // {
          //   original: "",
          //   thumbnail: "",
          // },
          {
            original: this.isVideo(item.ratingMedia[0])
              ? "https://storage.googleapis.com/assets.shopiads.ca/imagesProd/RatingVideoImage/icons-play.png"
              : item.ratingMedia[0],
            thumbnail: this.isVideo(item.ratingMedia[0])
              ? "https://storage.googleapis.com/assets.shopiads.ca/imagesProd/RatingVideoImage/icons-play.png"
              : item.ratingMedia[0],
            ...(this.isVideo(item.ratingMedia[0]) && {
              embedUrl: item.ratingMedia[0],
            }),
            ...(this.isVideo(item.ratingMedia[0]) && {
              renderItem: this._renderVideo.bind(this),
            }),
            //embedUrl: 'https://storage.googleapis.com/assets.shopiads.ca/imagesProd/RatingVideoImage/Mississauga%2084/2e2e23cf31b6a31b006cf35ac32bd18f.mp4',
            //description:"viedeo",
            //renderItem: this._renderVideo.bind(this)
          },
          ...(item.ratingMedia.length > 1
            ? [
              {
                original: this.isVideo(item.ratingMedia[1])
                  ? "https://storage.googleapis.com/assets.shopiads.ca/imagesProd/RatingVideoImage/icons-play.png"
                  : item.ratingMedia[1],
                thumbnail: this.isVideo(item.ratingMedia[1])
                  ? "https://storage.googleapis.com/assets.shopiads.ca/imagesProd/RatingVideoImage/icons-play.png"
                  : item.ratingMedia[1],
                ...(this.isVideo(item.ratingMedia[1]) && {
                  embedUrl: item.ratingMedia[1],
                }),
                ...(this.isVideo(item.ratingMedia[1]) && {
                  renderItem: this._renderVideo.bind(this),
                }),
              },
            ]
            : []),
          ...(item.ratingMedia.length > 2
            ? [
              {
                original: this.isVideo(item.ratingMedia[2])
                  ? "https://storage.googleapis.com/assets.shopiads.ca/imagesProd/RatingVideoImage/icons-play.png"
                  : item.ratingMedia[2],
                thumbnail: this.isVideo(item.ratingMedia[2])
                  ? "https://storage.googleapis.com/assets.shopiads.ca/imagesProd/RatingVideoImage/icons-play.png"
                  : item.ratingMedia[2],
                ...(this.isVideo(item.ratingMedia[2]) && {
                  embedUrl: item.ratingMedia[2],
                }),
                ...(this.isVideo(item.ratingMedia[2]) && {
                  renderItem: this._renderVideo.bind(this),
                }),
              },
            ]
            : []),
          ...(item.ratingMedia.length > 3
            ? [
              {
                original: this.isVideo(item.ratingMedia[3])
                  ? "https://storage.googleapis.com/assets.shopiads.ca/imagesProd/RatingVideoImage/icons-play.png"
                  : item.ratingMedia[3],
                thumbnail: this.isVideo(item.ratingMedia[3])
                  ? "https://storage.googleapis.com/assets.shopiads.ca/imagesProd/RatingVideoImage/icons-play.png"
                  : item.ratingMedia[3],
                ...(this.isVideo(item.ratingMedia[3]) && {
                  embedUrl: item.ratingMedia[3],
                }),
                ...(this.isVideo(item.ratingMedia[3]) && {
                  renderItem: this._renderVideo.bind(this),
                }),
              },
            ]
            : []),
        ];
        mediaItem[index] = images;
      }
    });
    this.setState({ mediaItem: mediaItem });
  }
  isVideo(reviewUrl) {
    const videos = ["mp4", "3gp", "ogg", "mov", "wmv", "flv", "avi", "mkv"];
    const url = new URL(reviewUrl);
    const extension = url.pathname.split(/\.(?=[^\.]+$)/)[1];
    console.log("extension>>>>>>>>>>>>>>>>>>>>>>>>>>>", extension);
    let flag = false;
    if (videos.includes(extension)) {
      flag = true;
    }
    console.log("isVideo>>>>>>>>>>>>>>>>>>>>>>>>>>>", flag);
    return flag;
  }

  slideClick = (newValue) => {
    this._resetVideo();
    // console.log(newValue);
    var elements = document.getElementsByClassName(
      "image-gallery-slide-wrapper"
    );
    for (var i in elements) {
      if (elements.hasOwnProperty(i)) {
        elements[i].className = "image-gallery-slide-show";
        // elements.insertRule(".display {block}", 0);
      }
    }
  };

  onImageCilck = (index) => {
    this.setState({ open: !this.state.open });
    this.setState({ key: index });
  };

  imageGallery(items, index) {
    return items.map((item) => (
      <div
        className="ml-4 gallery-items"
        onClick={this.onImageCilck.bind(this, index)}
      >
        {item.embedUrl ? (
          <ReactPlayer
            playIcon
            width="100px"
            height="50px"
            url={item.embedUrl}
          />
        ) : (
          <img style={{ width: "50px", height: "50px" }} src={item.thumbnail} />
        )}
      </div>
    ));
  }

  makeLikes(newValue) {
    if (!this.state.userInfo?.email) this.setState({ loginForm: true });
    else {
      this.setState({ likesLoading: true });
      if (
        this.props.allLikes.allLikes !== undefined &&
        this.props.allLikes.allLikes.filter((e) => e.type === "Product") &&
        this.props.allLikes.allLikes.filter((e) => e.productId === newValue)
          .length === 0 &&
        !this.props.loading.loading &&
        !this.state.likesLoading
      ) {
        API.makeLikes(this.state.userInfo?.email, newValue, "product")
          .then((result1) => {
            if (result1 != undefined) {
              this.props.getUserSnips(this.state.userInfo?.email, "like");
              this.setState({ likesLoading: false });
              API.productById("gust", newValue)
                .then((result) => {
                  if (result !== undefined) {
                    this.setState({ likes: result.totalLikes });
                    this.setState({ liked: true });
                  }
                })
                .catch((error) => {
                  this.setState({ loading: false });
                  console.log(error);
                  // console.log(error);
                });
            } else {
              this.setState({ likesLoading: false });
            }
          })
          .catch((error) => {
            console.log(error);
            this.setState({ likesLoading: false });
          });
      } else if (!this.props.loading.loading && !this.state.likesLoading && this.props.allLikes.allLikes != undefined && this.props.allLikes.allLikes.filter((e) => e.productId == newValue && e.activityType == "like").length > 0) {
        const productId =
          this.props.allLikes.allLikes !== undefined &&
          this.props.allLikes.allLikes.filter(
            (e) => e.productId === newValue
          )[0].id;
        API.makeUnLikes(
          this.state.userInfo?.email,
          newValue,
          "product",
          productId
        )
          .then((result1) => {
            if (result1 !== undefined) {
              this.props.getUserSnips(this.state.userInfo?.email, "like");
              this.setState({ likesLoading: false });
              API.productById("gust", newValue)
                .then((result) => {
                  if (result !== undefined) {
                    this.setState({ likes: result.totalLikes });
                    this.setState({ liked: false });
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            } else {
              this.setState({ likesLoading: false });
            }
          })
          .catch((error) => {
            console.log(error);
            this.setState({ likesLoading: false });
          });
      }
    }
  }

  filterClick(value) {
    let obj = [];
    // setActiveFilter(value);
    this.setState({ activeFilter: value });
    if (value == "all") {
      this.setState({ productRating: this.state.productRatingData });
      this.setRatingMedia(this.state.productRatingData);
      // setProductRating(productRatingData);
    } else if (value == "c") {
      console.log("filter cond>>>>>>>>>>>>>>", value);
      obj = this.state.productRatingData.filter(
        (item) =>
          item.comment != "" &&
          item.comment != null &&
          item.comment != undefined
      );
      console.log("filter Value>>>>>>>>>>>>>>", obj);
      this.setState({ productRating: obj });
      this.setRatingMedia(obj);
    } else if (value == "m") {
      console.log("filter cond>>>>>>>>>>>>>>", value);
      obj = this.state.productRatingData.filter(
        (item) =>
          item.ratingMedia != "" &&
          item.ratingMedia != null &&
          item.ratingMedia != undefined &&
          item.ratingMedia.length > 0
      );
      console.log("filter Value>>>>>>>>>>>>>>", obj);
      this.setState({ productRating: obj });
      this.setRatingMedia(obj);
    } else {
      console.log("filter cond>>>>>>>>>>>>>>", value);
      obj = this.state.productRatingData.filter((item) => item.rating == value);
      console.log("filter Value>>>>>>>>>>>>>>", obj);
      this.setState({ productRating: obj });
      this.setRatingMedia(obj);
    }
  }

  _resetVideo() {
    console.log("viedo reset>>>>>>>>>>>>>>>");
    this.setState({ showVideo: {} });

    if (this.state.showPlayButton) {
      this.setState({ showGalleryPlayButton: true });
    }

    if (this.state.showFullscreenButton) {
      this.setState({ showGalleryFullscreenButton: true });
    }
  }


  _toggleShowVideo(url) {
    this.state.showVideo[url] = !Boolean(this.state.showVideo[url]);
    this.setState({
      showVideo: this.state.showVideo,
    });

    if (this.state.showVideo[url]) {
      if (this.state.showPlayButton) {
        this.setState({ showGalleryPlayButton: false });
      }

      if (this.state.showFullscreenButton) {
        this.setState({ showGalleryFullscreenButton: false });
      }
    }
  }

  _renderVideo(item) {
    console.log("item>>>>>>>>>>>>>>>>>>>", item);
    console.log("showVideo>>>>>>>>>>>>>>>>>>>", this.state.showVideo);
    this._toggleShowVideo.bind(this, item.embedUrl);
    return (
      <div>
        {this.state.showVideo[item.embedUrl] ? (
          <div className="video-wrapper">
            <a
              className="close-video"
              onClick={this._toggleShowVideo.bind(this, item.embedUrl)}
            ></a>
            {/*  <iframe
                  width='560'
                  height='315'
                  src={item.embedUrl}
                  frameBorder='0'
                  allowFullScreen
                >
              </iframe>*/}
            <video width="560" height="315" controls autoPlay muted>
              <source
                src={item.embedUrl}
                type={"video/" + item.embedUrl.split(/\.(?=[^\.]+$)/)[1]}
              />
            </video>
          </div>
        ) : (
          <a onClick={this._toggleShowVideo.bind(this, item.embedUrl)}>
            <div className="play-button"></div>
            <img className="image-gallery-image" src={item.original} />
            {item.description && (
              <span
                className="image-gallery-description"
                style={{ right: "0", left: "initial" }}
              >
                {item.description}
              </span>
            )}
          </a>
        )}
      </div>
    );
  }

  viewStoreClick(storeId) {
    this.props.history.push(`/storeDetails/` + storeId);
  }

  viewAllCatProductClick(catId) {
    window.scrollTo(0, 0);
    this.props.history.push(`/product/` + catId);
  }

  productCardClick(newValue, productId) {
    this.props.history.push(`/productDetail/` + newValue + "/" + productId);
  }

  /* ratingClick(newValue){
   // history.push(`/storeDetails/` + newValue);
   if (!userInfo?.email) setLoginForm(true);
   else
     setRatingForm(true);
   // console.log("Under Development..............")
 
 };*/

  ratingClick(newValue) {
    // history.push(`/storeDetails/` + newValue);
    if (!this.state.userInfo?.email) this.setState({ loginForm: true });
    else this.setState({ ratingForm: true });
    // console.log("Under Development..............")
  }

  returnClick(newValue) {
    window.scrollTo(0, 0);
    this.props.history.push(newValue);
  }

  imageClick(item) {
    this.setState({ displayImage: item });
  }

  toUpperCaseFilter = (d) => {
    return d.toUpperCase();
  };

  handleVariantShopifyChanage = (e, index, itemValue) => {
    if (itemValue != undefined && itemValue != null && itemValue != "-1") {
      let op1 = this.state.selectedVariantOption1
      let op2 = this.state.selectedVariantOption2
      let op3 = this.state.selectedVariantOption3
      let productData = [];

      if (index == 0) {
        this.setState({ selectedVariantOption1: itemValue });
        op1 = itemValue

      }
      if (index == 1) {
        this.setState({ selectedVariantOption2: itemValue });
        op2 = itemValue
      }
      if (index == 2) {
        this.setState({ selectedVariantOption3: itemValue });
        op3 = itemValue
      }
      productData = this.state.variantList.find(function (item) {
        return item.variantOption1 == op1 && item.variantOption2 == op2 && item.variantOption3 == op3;
      });
      console.log({ productData })
      if (productData) {
        this.setState({ productId: productData.id })
      } else {
        // this.setState({ productId: this.state.mainProductId })

      }
    }
  }

  followStore = (newValue) => {
    if (!this.state.userInfo?.email) this.setState({ loginForm: true });
    else {
      API.followStore(this.state.userInfo?.email, newValue, "follow")
        .then((result) => {
          if (result != undefined) {
            this.setState({ disabled: !this.state.disabled });

            //setCollectionProduct(result);
          } else {
            //setCollectionProduct([]);
          }
        })
        .catch((error) => {
          //setLoading(false);
          console.log(error);
        });
    }
  };
  openDiscountPage = () => {
    this.setState({ discountForm: true });

  };

  render() {
    const { classes } = this.props;
    SwiperCore.use([Navigation, Pagination]);
    return (<>
      <div className="container-fluid">
        <Backdrop
          style={{ backgroundColor: 'rgba(229,229,229, 0.1)', position: 'absolute', zIndex: 9999 }}
          open={this.state.loading}
        >
          <CircularProgress color="inherit" style={{ color: "#1d6042" }} />
        </Backdrop>
        
        <div className="row ml-5">
          
          <div className="col-md-11 mx-auto">
            <div className="row">
              <div className="col-md-10 pl-0">
                
                <div className="mb-3 bg-white">
                  
                  <div class="title-m ml-2">
                      <i
                        style={{
                          fontSize: "25px",
                          marginTop:'10px',
                          color: "#1d6042",
                        cursor: "pointer", position: 'relative',
                        display: 'flex',
                          alignItems:'center'
                        }}
                        className="fa fa-arrow-circle-left"
                        aria-hidden="true"
                        title="Back to Login"
                        onClick={() => this.props.history.goBack()}
                      >
                        <p style={{
                          display:'inline',
                          marginBottom: "10px",
                          fontSize: '20px',
                          margin: '5px',
                          color:'black'
                        }}>Buyer Reviews</p>
                      </i>
                    </div>
                  <div className="bg-light row mx-0 p-4 border">
                    <div className="col-auto">
                      <div className="product-raitng-score">
                        <div>
                          <span className="rating-score">
                            {this.state.productData.avgRating !=
                              undefined &&
                              Number(this.state.productData.avgRating) > 0
                              ? this.state.productData.avgRating
                              : 0}
                          </span>{" "}
                          <span className="rating-score">
                            out of 5
                          </span>
                        </div>
                        <div class="rating-star mt-1">
                          <ReactStars
                            key={`stars_${Number(this.state.productData.avgRating)}`}
                            count={5}
                            size={24}
                            isHalf={true}
                            edit={false}
                            value={Number(this.state.productData.avgRating)}
                            activeColor="#ffd700"
                          />
                        </div>
                      </div>
                    </div>

                    {/* rating scrore end here */}
                    <div className="col-md-10">
                      <Button
                        onClick={this.filterClick.bind(this, "all")}
                        variant={
                          this.state.activeFilter == "all"
                            ? "contained"
                            : "outlined"
                        }
                        color="primary"
                        className="mr-2 mb-2"
                      >
                        all
                      </Button>
                      <Button
                        onClick={this.filterClick.bind(this, "5")}
                        variant={
                          this.state.activeFilter == "5"
                            ? "contained"
                            : "outlined"
                        }
                        color="primary"
                        className="mr-2 mb-2"
                      >
                        5 star ({this.state.ratingFilterCount5})
                      </Button>
                      <Button
                        onClick={this.filterClick.bind(this, "4")}
                        variant={
                          this.state.activeFilter == "4"
                            ? "contained"
                            : "outlined"
                        }
                        color="primary"
                        className="mr-2 mb-2"
                      >
                        4 star ({this.state.ratingFilterCount4})
                      </Button>
                      <Button
                        onClick={this.filterClick.bind(this, "3")}
                        variant={
                          this.state.activeFilter == "3"
                            ? "contained"
                            : "outlined"
                        }
                        color="primary"
                        className="mr-2 mb-2"
                      >
                        3 star ({this.state.ratingFilterCount3})
                      </Button>
                      <Button
                        onClick={this.filterClick.bind(this, "2")}
                        variant={
                          this.state.activeFilter == "2"
                            ? "contained"
                            : "outlined"
                        }
                        color="primary"
                        className="mr-2 mb-2"
                      >
                        2 star ({this.state.ratingFilterCount2})
                      </Button>
                      <Button
                        onClick={this.filterClick.bind(this, "1")}
                        variant={
                          this.state.activeFilter == "1"
                            ? "contained"
                            : "outlined"
                        }
                        color="primary"
                        className="mr-2 mb-2"
                      >
                        1 star ({this.state.ratingFilterCount1})
                      </Button>
                      {/*<Button
                            onClick={this.filterClick.bind(this, "c")}
                            variant={
                              this.state.activeFilter == "c"
                                ? "contained"
                                : "outlined"
                            }
                            color="primary"
                            className="mr-2 mb-2"
                          >
                            with comments ({this.state.ratingFilterCountC})
                          </Button>
                          <Button
                            onClick={this.filterClick.bind(this, "m")}
                            variant={
                              this.state.activeFilter == "m"
                                ? "contained"
                                : "outlined"
                            }
                            color="primary"
                            className="mr-2 mb-2"
                          >
                            With Media ({this.state.ratingFilterCountM})
                          </Button>*/}
                    </div>
                    <div className="col-md-12 mt-3">
                      <div className="row align-items-center">
                        <div className="col-auto pr-0">
                          {/* <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" fill="#1d6042" class="bi bi-patch-check-fill" viewBox="0 0 16 16">
                          <path d="M10.067.87a2.89 2.89 0 0 0-4.134 0l-.622.638-.89-.011a2.89 2.89 0 0 0-2.924 2.924l.01.89-.636.622a2.89 2.89 0 0 0 0 4.134l.637.622-.011.89a2.89 2.89 0 0 0 2.924 2.924l.89-.01.622.636a2.89 2.89 0 0 0 4.134 0l.622-.637.89.011a2.89 2.89 0 0 0 2.924-2.924l-.01-.89.636-.622a2.89 2.89 0 0 0 0-4.134l-.637-.622.011-.89a2.89 2.89 0 0 0-2.924-2.924l-.89.01-.622-.636zm.287 5.984-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708.708z" />
                </svg>*/}
                          <img
                            src="https://storage.googleapis.com/assets.shopiads.ca/imagesProd/Verified_Image.png"
                            width="60"
                          ></img>
                        </div>
                        <div className="col-auto">
                          {/* <b>Verified Reviews</b> */}
                          <p className="mb-0">
                            Ratings and reviews are provided by verified buyers who had purchased through ShopiAds!
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  {this.state.productRating.slice(0, this.state.numberOfRating).map((item, index) => (
                    <div className="rating-list mt-4">
                      <ul class="list-unstyled">
                        <li class="media">
                          <div class="">
                            <img
                              src={Profile}
                              style={{
                                width: "30px",
                                borderRadius: "50%"
                              }}
                              //className={classes.userIcon}
                              //onClick={handleMenu}
                              alt="PROFILE"
                            />
                          </div>
                          <div class="media-body">
                            <small class="mt-0 mb-1 ml-2">{item.userName}</small>
                            <div class="rating-star mt-1">
                              <ReactStars
                                key={`stars_${Number(item?.rating)}`}
                                count={5}
                                size={24}
                                isHalf={true}
                                edit={false}
                                value={item?.rating}
                                activeColor="#ffd700"
                              />
                            </div>
                            <p className="text-muted mb-1 small mt-3">
                              Reviewed on{" "}
                              <Moment
                                tz="CST"
                                format="DD MMM YYYY"
                                filter={this.toUpperCaseFilter}
                              >
                                {item.createdOn}
                              </Moment>
                            </p>
                            <b className="text-purchased mb-1">
                              Verified Purchase
                            </b>
                            <p className="medium">{item.comment}</p>

                            {item.ratingMedia != null &&
                              item.ratingMedia != undefined &&
                              item.ratingMedia.length > 0 &&
                              this.state.mediaItem != undefined &&
                              this.state.mediaItem != null &&
                              this.state.mediaItem.length > 0 ? (
                              <div className="gallery">
                                {this.imageGallery(
                                  this.state.mediaItem[index],
                                  index
                                )}
                                {
                                  <Dialog
                                    // onClick={this.handleClose}
                                    aria-labelledby="customized-dialog-title"
                                    open={this.state.open}
                                    className="dialog-container"
                                  >
                                    <MuiDialogTitle>
                                      <IconButton
                                        aria-label="close"
                                        className="close-icon"
                                        onClick={this.onImageCilck.bind(
                                          this
                                        )}
                                      >
                                        <CloseIcon />
                                      </IconButton>
                                    </MuiDialogTitle>
                                    <Swiper
                                      spaceBetween={20}
                                      slidesPerView={1}
                                      navigation
                                      loop={true}
                                      onSlideChange={(swiper) => {
                                        console.log("swiper");
                                        // handlePause(swiper.previousIndex);
                                      }}
                                      onSwiper={(swiper) =>
                                        console.log(swiper)
                                      }
                                      className="customized-images"
                                      style={{
                                        position: "none !imporatnt",
                                      }}
                                    >
                                      {this.state.mediaItem[
                                        this.state.key
                                      ] !== undefined
                                        ? this.state.mediaItem[
                                          this.state.key
                                        ].map((item, index) => (
                                          <SwiperSlide
                                            style={{
                                              width: "200px !important",
                                            }}
                                          >
                                            <div>
                                              {/* {item.embedUrl ? (
                                                      <ReactPlayer
                                                        controls
                                                        playIcon={true}
                                                        width="600px"
                                                        url={item.embedUrl}
                                                      />
                                                    ) : ( */}
                                              <img
                                                alt="images"
                                                style={{
                                                  // paddingLeft: "2%",
                                                  width: "600px",
                                                  height: "500px",
                                                }}
                                                src={item.original}
                                              />
                                              {/* )} */}
                                            </div>
                                          </SwiperSlide>
                                        ))
                                        : ""}
                                    </Swiper>
                                  </Dialog>
                                }
                              </div>
                            ) : (
                              ""
                            )}
                            {/*  <div className="d-flex mt-3 justify-content-between">
                          <a href="#" className="text-muted "><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-hand-thumbs-up-fill" viewBox="0 0 16 16">
                            <path d="M6.956 1.745C7.021.81 7.908.087 8.864.325l.261.066c.463.116.874.456 1.012.965.22.816.533 2.511.062 4.51a9.84 9.84 0 0 1 .443-.051c.713-.065 1.669-.072 2.516.21.518.173.994.681 1.2 1.273.184.532.16 1.162-.234 1.733.058.119.103.242.138.363.077.27.113.567.113.856 0 .289-.036.586-.113.856-.039.135-.09.273-.16.404.169.387.107.819-.003 1.148a3.163 3.163 0 0 1-.488.901c.054.152.076.312.076.465 0 .305-.089.625-.253.912C13.1 15.522 12.437 16 11.5 16H8c-.605 0-1.07-.081-1.466-.218a4.82 4.82 0 0 1-.97-.484l-.048-.03c-.504-.307-.999-.609-2.068-.722C2.682 14.464 2 13.846 2 13V9c0-.85.685-1.432 1.357-1.615.849-.232 1.574-.787 2.132-1.41.56-.627.914-1.28 1.039-1.639.199-.575.356-1.539.428-2.59z" />
                          </svg> Helpful?</a>
                          <DropdownButton id="dropdown-item-button" title="Report">
                            <Dropdown.Item as="button">Report Abuse</Dropdown.Item>
                          </DropdownButton>
                </div>*/}
                          </div>
                        </li>
                      </ul>
                    </div>
                  ))}
                  {
                    this.state.productRating.length > this.state.numberOfRating && <a href="javascript:void(0)" onClick={() => { this.setState(oldState => ({...oldState, numberOfRating: oldState.numberOfRating + 5}))}} ><p>View more reviews</p></a>
                  }
                </div>


                <div class="pl-0 mb-3 mt-5">
                  <div class="d-flex align-items-center justify-content-between shopee-header-section__header pb-2">
                    <div class="shopee-header-section__header__title">
                      <div>SIMILAR PRODUCTS</div>
                    </div>
                    <button
                      onClick={() => {
                        this.props.history.push(
                          `/search/${Buffer.from(
                            this.state.productData.heading
                          ).toString("hex")}/all`
                        );
                      }}
                      className="shopee-button-no-outline"
                      style={{ color: COLORS.primary.main,fontWeight:"bold" }}
                    >
                      View All &nbsp;
                      <svg
                        enable-background="new 0 0 11 11"
                        viewBox="0 0 11 11"
                        x="0"
                        y="0"
                        height="12px"
                        width="12px"
                        className="icon-arrow-right"
                      >
                        <path d="m2.5 11c .1 0 .2 0 .3-.1l6-5c .1-.1.2-.3.2-.4s-.1-.3-.2-.4l-6-5c-.2-.2-.5-.1-.7.1s-.1.5.1.7l5.5 4.6-5.5 4.6c-.2.2-.2.5-.1.7.1.1.3.2.4.2z"></path>
                      </svg>
                    </button>
                  </div>
                  <Swiper
                    spaceBetween={10}
                    slidesPerView={5}
                    slidesPerGroup={5}
                    navigation
                  >
                    {this.state.sameStoreProduct !== undefined &&
                      this.state.sameStoreProduct != null &&
                      this.state.sameStoreProduct.length > 0 ? this.state.sameStoreProduct.map((item, index) => (
                        <SwiperSlide>
                          <div>
                            <ProductCard
                              branchName={item.storeName}
                              storeId={item.storeId}
                              productId={item.id}
                              img={item.images}
                              branchLogo={item.logo}
                              heading={item.heading}
                              avgRating={item.avgRating}
                              discount={item.discountPerc}
                              actualPrice={item.actualPrice}
                              offerPrice={item.offerPrice}
                              totalLikes={item.totalLikes}
                              key={item.id}
                            />
                          </div>
                        </SwiperSlide>
                      ))
                      : ""}
                  </Swiper>
                </div>
              </div>
              <div className="col-md-2 pr-0">
                {/* side product start here */}
                {this.state.sameCatProduct != undefined &&
                  this.state.sameCatProduct != null &&
                  this.state.sameCatProduct.length > 0
                  ? this.state.sameCatProduct.map((item, index) =>
                    index <= 3 && item.id != this.state.productData.id ? (
                      <div className="mb-3" style={{ width: "250px" }}>
                        <ProductCard
                          branchName={item.storeName}
                          storeId={item.storeId}
                          productId={item.id}
                          img={item.images}
                          branchLogo={item.logo}
                          heading={item.heading}
                          avgRating={item.avgRating}
                          discount={item.discountPerc}
                          actualPrice={item.actualPrice}
                          offerPrice={item.offerPrice}
                          totalLikes={item.totalLikes}
                          key={item.id}
                        />
                      </div>
                    ) : (
                      ""
                    )
                  )
                  : ""}
                {/* side product end here */}

                {this.state.sameCatProduct != undefined &&
                  this.state.sameCatProduct != null &&
                  this.state.sameCatProduct.length > 4 ? (
                  <div className="text-center">
                    <Button
                      variant="contained"
                      onClick={this.viewAllCatProductClick.bind(
                        this,
                        this.state.sameCatProduct[0].categories[0]
                      )}
                        color="primary"
                      style={{ color: COLORS.primary.main,fontWeight:"bold" }}
                    >
                      View All
                    </Button>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-4"></div>

        {this.state.ratingForm && (
          <RatingForm
            handleOpen={this.handleOpenForm}
            handleClose={this.handleCloseForm}
            open={this.state.ratingForm}
            storeId={this.state.productData.storeId}
            productId={this.state.productData.id}
            offerId={""}
            type={"Product"}
          />
        )}
        {this.state.loginForm && (
          <LoginForm
            handleOpen={this.handleLoginOpenForm}
            handleClose={this.handleLoginCloseForm}
            open={this.state.loginForm}
          />
        )}
        <ShopifyStoreDiscount
          handleOpen={this.handleOpenDiscountForm}
          handleClose={this.handleCloseDiscountForm}
          storeData={this.state.storeData}
          open={this.state.discountForm}
        />
        <ToastContainer />
      </div>

    </>);
  }
}



const mapStateToProps = (state) => ({
  allSnips: state.snips,
  allLikes: state.snips,
  loading: state.snips,
  selectedRefMarketPlace: state.location.marketPlace,
});

const mapDispatchToProps = (dispatch) => {
  return {
    pushSnips: (snipBody) => pushSnipsData(snipBody),
    getUserSnips: (email, like) => getUserSnips(email, like),
  };
};
export default connect(mapStateToProps, mapDispatchToProps())(Rating);